import { Box, Button, CircularProgress, Typography } from "@mui/material"
import { Pagination } from "@mui/lab"
import AddButton from "../../components/shared/AddButton"
import React, { FunctionComponent, useEffect, useState } from "react"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/ScreenContainer"
import useGetHouseById from "../Organizations/hooks/useGetHouseById"
import { scopes } from "../../scopes"
import { useAuth } from "../../services/auth-service"
import useGetAnnouncements from "./hooks/useGetAnnouncements"
import AnnouncementsTable from "./components/AnnouncementsTable"
import AnnouncementDialog from "./components/AnnouncementDialog"
import styles from "./AnnouncementsScreen.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/pro-regular-svg-icons"
import { lightTheme } from "../../constants/theme"
import { Announcement } from "../../types"

const AnnouncementsScreen: FunctionComponent = () => {
  const {
    getHouseById,
    house,
    loading: houseLoading,
    error: houseError,
  } = useGetHouseById()
  const {
    getAnnouncements,
    paginationData,
    announcements,
    loading: announcementsLoading,
    error: announcementError,
  } = useGetAnnouncements()
  const { hasScope } = useAuth()

  const [createAnnouncementOpen, setCreateAnnouncementOpen] = useState(false)
  const [activeFilter, setActiveFilter] = useState("all")
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const houseId = localStorage.getItem("activeOrganizationId")

        if (houseId) {
          await getHouseById(Number(houseId))
          await getAnnouncements(Number(houseId))

          if (house) {
            localStorage.setItem("activeOrganization", JSON.stringify(house))
          }
        }
      } catch (err) {
        console.error(`Error loading organization: ${houseError}`)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const announcementOpenParam = urlSearchParams.get("createAnnouncementOpen")

    if (
      announcementOpenParam &&
      announcementOpenParam.toLowerCase() === "true"
    ) {
      setCreateAnnouncementOpen(true)
    }
  }, [])

  useEffect(() => {
    const houseId = localStorage.getItem("activeOrganizationId")
    if (activeFilter === "all") {
      getAnnouncements(Number(houseId))
    } else if (activeFilter === "active") {
      getAnnouncements(Number(houseId), true)
    } else if (activeFilter === "inactive") {
      getAnnouncements(Number(houseId), false)
    }
  }, [activeFilter])

  const canCreateAnnouncement = hasScope(
    scopes.organization.announcements.create
  )
  const canEdit = hasScope(scopes.organization.announcement.update)

  const activeFilterStyle = { 
    color: lightTheme.palette.text.primary, 
    fontWeight: "bold",
    '&::after': {
      content: '""',
      display: 'block',
      width: '100%',
      height: '2px',
      backgroundColor: lightTheme.palette.primary.main,
      marginTop: '2px'
  }}

  const inactiveFilterStyle = {
    color: lightTheme.palette.text.primary,
    opacity: 0.8
  }

  if (houseLoading) {
    return (
      <ScreenContainer>
        <Box
          display="flex"
          width="100%"
          sx={{
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            alignSelf: "center",
            mb: "24px",
          }}
        >
          <CircularProgress />
        </Box>
      </ScreenContainer>
    )
  }

  return (
    <>
    {canCreateAnnouncement && (
      <Box className={styles.createAnnouncementButtonContainer} onClick={() => setCreateAnnouncementOpen(true)}>
      <AddButton className="className" />
      </Box>
    )}
    <ScreenContainer
      requiresScope={scopes.organization.announcements.create}
    >
      <Box
        display="flex"
        width="100%"
        sx={{
          flexDirection: "column",
          mb: "24px",
        }}
        className={styles.announcementsScreen}
      >
        {announcementError && <ErrorComponent error={announcementError} />}
        {announcementsLoading && <CircularProgress />}
        <Typography variant="h3" className={styles.title}>
          Announcements
        </Typography>
        <Box className={styles.tableFilterMenu}>
          <button className={styles.filterButton} onClick={() => setActiveFilter("all")}>
          <Typography variant="body1" sx={activeFilter === "all" ? activeFilterStyle : inactiveFilterStyle}>
            All
          </Typography>
          </button>
          <button className={styles.filterButton} onClick={() => setActiveFilter("active")}>
          <Typography variant="body1" sx={activeFilter === "active" ? activeFilterStyle : inactiveFilterStyle}>
            Active
          </Typography>
          </button>
          <button className={styles.filterButton} onClick={() => setActiveFilter("inactive")}>
          <Typography variant="body1" sx={activeFilter === "inactive" ? activeFilterStyle : inactiveFilterStyle}>
            Inactive
          </Typography>
          </button>
        </Box>
        <AnnouncementsTable
          announcements={announcements}
          paginationData={paginationData}
          refreshData={async () => {
            const organizationId = localStorage.getItem("activeOrganizationId")
            if (organizationId) {
              await getAnnouncements(Number(organizationId))
            }
          }}
        />
      </Box>
      <AnnouncementDialog
        isOpen={createAnnouncementOpen}
        close={() => setCreateAnnouncementOpen(false)}
        onSuccess={async () => {
          const organizationId = localStorage.getItem("activeOrganizationId")
          if (organizationId) {
            await getAnnouncements(Number(organizationId))
          }
        }}
      />
    </ScreenContainer>
    </>
  )
}

export default AnnouncementsScreen
