import React, { useEffect, useState } from "react"
import {
  Help,
  Menu as MenuIcon,
  Notifications as NotificationsIcon,
} from "@mui/icons-material"
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { capitalizeFirstLetter } from "../../utils/formatters"
import LogoutButton from "./LogoutButton"
import useBreakpoint from "../../hooks/useBreakpoint"
import useGetMyHouses from "../../screens/Users/hooks/useGetMyHouses"
import { useAuth } from "../../services/auth-service"
import useGetMyUnreadNotificationCount from "../../screens/Users/hooks/useGetMyUnreadNotificationCount"
import { scopes } from "../../scopes"
import Intercom, { show } from "@intercom/messenger-js-sdk"
import { useMenu } from "./MenuContext"
import config from "../../config"
import styles from "./BaseLayout.module.css"
import { useTheme } from "@mui/material/styles"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import icons from "../../assets/Icons/icons"
import redLogo from "../../assets/redLogo.svg"
import useGetHouseById from "../../screens/Organizations/hooks/useGetHouseById"

interface LinkItem {
  path: string
  label?: string
  hasScope?: string
  icon?: React.ReactNode
  activeResolver?: () => boolean
}

const BaseLayout = () => {
  const theme = useTheme()
  const { setMenuIsShowing, menuIsShowing, toggleMenu } = useMenu()
  const navigate = useNavigate()
  const location = useLocation()
  const breakpoint = useBreakpoint()

  const [dropdownIsShowing, setDropdownIsShowing] = useState(false)
  const [collapsedWidth, setCollapsedWidth] = useState(100)
  const [mobile, setMobile] = useState(false)

  const { hasScope } = useAuth()
  const auth = useAuth()
  const userId = auth.getUser()?.id
  const userEmail = auth.getUser()?.email
  const profilePhoto = auth.getUser()?.profile_photo
  const userName = auth.getUser()?.name

  const { getMyHouses, houses, loading } = useGetMyHouses()
  const { getUnreadNotificationCount, unreadCount } =
    useGetMyUnreadNotificationCount()

  useEffect(() => {
    if (auth.isAuthenticated()) {
      getMyHouses()

      if (!loading && houses?.length === 0) {
        // Redirect to create house page
        navigate(`/organizations/create`)
      }

      getUnreadNotificationCount()
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const dropdown = document.getElementById("dropdown")
    if (dropdown) {
      if (dropdownIsShowing) {
        dropdown.style.display = "flex"
      } else {
        dropdown.style.display = "none"
      }
    }
  }, [dropdownIsShowing])

  useEffect(() => {
    if (breakpoint === "xxs" || breakpoint === "xs" || breakpoint === "sm") {
      setCollapsedWidth(0)
      setMobile(true)
      setMenuIsShowing(false)
    } else {
      setCollapsedWidth(100)
      setMobile(false)
      setMenuIsShowing(true)
    }
    // eslint-disable-next-line
  }, [breakpoint])

  const organizationLinks: LinkItem[] = [
    {
      path: "/",
      label: "Dashboard",
      icon: <FontAwesomeIcon icon={icons.grid} />,
      activeResolver: () =>
        location?.pathname === "/" || location?.pathname === "",
    },
  ]

  const featuresLinks: LinkItem[] = [
    {
      path: "announcements",
      label: "Announcements",
      icon: <FontAwesomeIcon icon={icons.bullhorn} />,
      hasScope: scopes.organization.announcements.create,
    },
    {
      path: "attendance",
      label: "Attendance",
      icon: <FontAwesomeIcon icon={icons.clipBoardUser} />,
      hasScope: scopes.organization.attendanceItems.read,
    },
    {
      path: "gallery",
      label: "Gallery",
      icon: <FontAwesomeIcon icon={icons.gallery} />,
      hasScope: scopes.organization.assets.read,
    },
    {
      path: "guest-lists",
      label: "Guest Lists",
      icon: <FontAwesomeIcon icon={icons.listTree} />,
      hasScope: scopes.organization.guestLists.create,
    },
  ]

  const studyHoursLinks: LinkItem[] = [
    {
      path: "study-hours",
      label: "Study Hours",
      icon: <FontAwesomeIcon icon={icons.clock} />,
      hasScope: scopes.organization.members.studyHours.read,
      activeResolver: () => location?.pathname === "/study-hours",
    },
    {
      path: "study-hours/locations",
      label: "Study Locations",
      icon: <FontAwesomeIcon icon={icons.location} />,
      hasScope: scopes.organization.studyHours.locations.create,
      activeResolver: () =>
        location?.pathname.startsWith("/study-hours/locations"),
    },
  ]

  const settingsLinks: LinkItem[] = [
    {
      path: "members",
      hasScope: scopes.organization.members.read,
      icon: <FontAwesomeIcon icon={icons.peopleRoof} />,
      activeResolver: () => location?.pathname === "/members",
    },
    {
      path: "members/points",
      label: "Points",
      icon: <FontAwesomeIcon icon={icons.tally} />,
      hasScope: scopes.organization.member.points.read,
      activeResolver: () => location?.pathname.startsWith("/members/points"),
    },
    {
      path: "settings",
      hasScope: scopes.organization.read,
      icon: <FontAwesomeIcon icon={icons.gear} />,
    },
  ]

  const adminLinks: LinkItem[] = [
    {
      path: "internal/events/deadletter",
      label: "Deadletter",
      hasScope: scopes.deadletterEvents.read,
    },
    {
      path: "internal/schools",
      label: "Schools",
      hasScope: scopes.schools.create,
    },
  ]

  const getLinks = (links: LinkItem[]) => {
    const filteredLinks = links.filter((link) => {
      if (!link.hasOwnProperty("hasScope") && !link.hasScope) {
        return true
      } else {
        return hasScope(link?.hasScope as string)
      }
    })

    return {
      count: filteredLinks?.length,
      component: (
        <List sx={{ padding: "0px" }}>
          {filteredLinks.map((item, index) => {
            let isActive: boolean = false
            if (item?.activeResolver) {
              isActive = item.activeResolver()
            } else {
              isActive = location?.pathname.startsWith(`/${item.path}`)
            }

            const textProps: any = {
              style: {
                fontWeight: "bold",
                color: isActive
                  ? theme.palette.background.default
                  : theme.palette.text.primary,
              },
            }

            const textPropsCollapsed: any = {
              style: {
                display: "none",
              },
            }

            return (
              <div className={styles.listItemContainer}>
                <ListItem
                  className={
                    menuIsShowing ? styles.listItem : styles.listItemCollapsed
                  }
                  button
                  key={index}
                  onClick={() => navigate(item.path)}
                  sx={{
                    backgroundColor: isActive
                      ? theme.palette.primary.main
                      : "inherit",
                    "&:hover": isActive
                      ? { backgroundColor: theme.palette.primary.main }
                      : {
                          backgroundColor: theme.palette.secondary.main,
                        },
                    borderRadius: "8px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    className={
                      menuIsShowing ? styles.icon : styles.iconCollapsed
                    }
                  >
                    <Typography
                      sx={{
                        color: isActive
                          ? theme.palette.background.default
                          : theme.palette.text.primary,
                        fontSize: "1.5rem",
                      }}
                    >
                      {item.icon}
                    </Typography>
                  </Box>
                  <ListItemText
                    sx={
                      menuIsShowing ? { display: "flex" } : { display: "none" }
                    }
                    primary={item.label || capitalizeFirstLetter(item.path)}
                    primaryTypographyProps={
                      menuIsShowing ? textProps : textPropsCollapsed
                    }
                  />
                </ListItem>
              </div>
            )
          })}
        </List>
      ),
    }
  }

  const filteredOrganizationLinks = getLinks(organizationLinks)
  const filteredFeaturesLinks = getLinks(featuresLinks)
  const filteredStudyHoursLinks = getLinks(studyHoursLinks)
  const filteredSettingsLinks = getLinks(settingsLinks)
  const filteredAdminLinks = getLinks(adminLinks)

  const activeOrganization = localStorage.getItem("activeOrganization")
  const activeHouseName =
    (activeOrganization && JSON.parse(activeOrganization).name) || null

  const { getHouseById } = useGetHouseById()
  const setactiveOrganizationId = async (houseId: number) => {
    const newHouse = await getHouseById(houseId)
    localStorage.setItem("activeOrganizationId", `${houseId}`)
    localStorage.setItem("activeOrganization", JSON.stringify(newHouse))
    window.location.reload()
  }

  const toggleDropdown = () => {
    setDropdownIsShowing(!dropdownIsShowing)
  }

  const handleOrganizationClick = (path: string) => {
    navigate(path)
    toggleDropdown()
  }

  Intercom({
    app_id: config.intercomAppId,
    user_id: String(userId),
    name: userName,
    email: userEmail,
    hide_default_launcher: true,
  })

  return (
    <Box className={styles.containingBox} sx={{ display: "flex" }}>
      <AppBar
        position="fixed"
        sx={{
          marginLeft: menuIsShowing ? 225 : 100,
          backgroundColor: "#ff4444",
        }}
      >
        <Toolbar
          sx={menuIsShowing ? { marginLeft: "225px" } : { marginLeft: "0px" }}
        >
          <Box>
            <IconButton
              onClick={toggleMenu}
              size="large"
              sx={
                mobile ? { display: "block", mr: "10px" } : { display: "none" }
              }
            >
              <MenuIcon htmlColor="white" fontSize="large" />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box sx={{ p: 0, marginLeft: "auto" }}>
              <Tooltip title="Notifications">
                <Badge
                  badgeContent={unreadCount?.count || null}
                  color="warning"
                  onClick={() => navigate("/account/notifications")}
                  sx={{ cursor: "pointer" }}
                >
                  <NotificationsIcon htmlColor="white" fontSize="large" />
                </Badge>
              </Tooltip>
              <Tooltip title="Customer support">
                <Badge
                  badgeContent={null}
                  color="warning"
                  onClick={() => show()}
                  sx={{ cursor: "pointer", ml: 2, mr: 2 }}
                >
                  <Help htmlColor="white" fontSize="large" />
                </Badge>
              </Tooltip>
              <Tooltip title="Account">
                <IconButton>
                  <Avatar
                    onClick={() => navigate("/account")}
                    alt={userName}
                    src={`${profilePhoto}`}
                  />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="left"
        open={true}
        onClose={toggleMenu}
        variant="persistent"
        hideBackdrop
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          width: menuIsShowing ? 225 : collapsedWidth,
          "& .MuiPaper-root": {
            width: menuIsShowing ? 225 : collapsedWidth,
          },
          height: "calc(100vh-93px)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            height: "100px",
          }}
        >
          <IconButton onClick={toggleMenu} size="large" sx={{ mr: "0px" }}>
            <img
              height={45}
              src={redLogo}
              alt={"Greek Chapter Logo"}
              style={{ cursor: "pointer" }}
            />
          </IconButton>
        </Box>
        <Box
          className={styles.dropdown}
          sx={{ backgroundColor: theme.palette.background.default }}
          id="dropdown"
        >
          {houses?.map((house) => {
            return (
              <Button
                key={house.id}
                onClick={() => {
                  localStorage.setItem(
                    "activeOrganization",
                    JSON.stringify(house)
                  )
                  setactiveOrganizationId(house.id)
                  navigate("/")
                }}
                className={styles.organizationChoices}
                sx={{
                  backgroundColor:
                    house.name === activeHouseName
                      ? theme.palette.secondary.main
                      : "inherit",
                  "&:hover": { backgroundColor: theme.palette.secondary.main },
                }}
              >
                <Typography
                  className={styles.organizationChoices}
                  sx={{ color: theme.palette.text.primary }}
                >
                  <p className={styles.organizations}>{house.name}</p>
                </Typography>
              </Button>
            )
          })}
          <Divider />
          <Button
            onClick={() => handleOrganizationClick("/organizations/create")}
            className={styles.button}
            sx={{
              "&:hover": { backgroundColor: theme.palette.secondary.main },
            }}
          >
            <Typography
              sx={{ color: theme.palette.text.primary, marginRight: "15px" }}
            >
              <FontAwesomeIcon icon={icons.plus} />
            </Typography>
            <Typography
              sx={{ color: theme.palette.text.primary, fontWeight: "bold" }}
            >
              Create Organization
            </Typography>
          </Button>
          <Divider />
          <Button
            onClick={() => handleOrganizationClick("/organizations/join")}
            className={styles.button}
            sx={{
              "&:hover": { backgroundColor: theme.palette.secondary.main },
            }}
          >
            <Typography
              sx={{ color: theme.palette.text.primary, marginRight: "15px" }}
            >
              <FontAwesomeIcon icon={icons.join} />
            </Typography>
            <Typography
              sx={{ color: theme.palette.text.primary, fontWeight: "bold" }}
            >
              Join Organization
            </Typography>
          </Button>
        </Box>
        <Box
          overflow="auto"
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box>
            {activeHouseName && (
              <>
                <Box
                  className={
                    menuIsShowing
                      ? styles.organization
                      : styles.organizationCollapsed
                  }
                  sx={{
                    "&:hover": {
                      backgroundColor: theme.palette.secondary.main,
                    },
                  }}
                  onClick={() => toggleDropdown()}
                >
                  <FontAwesomeIcon icon={icons.houseUser} />
                  <p
                    style={{
                      margin: 0,
                      fontSize: 14,
                      userSelect: "none",
                      WebkitUserSelect: "none",
                    }}
                  >
                    <b>{menuIsShowing ? activeHouseName : ""}</b>
                  </p>
                  <FontAwesomeIcon icon={icons.down} />
                </Box>
                {filteredOrganizationLinks.count > 0 && (
                  <>{filteredOrganizationLinks.component}</>
                )}
                {filteredFeaturesLinks.count > 0 && (
                  <>{filteredFeaturesLinks.component}</>
                )}
                {filteredStudyHoursLinks.count > 0 && (
                  <>{filteredStudyHoursLinks.component}</>
                )}
                {filteredSettingsLinks.count > 0 && (
                  <>{filteredSettingsLinks.component}</>
                )}
                {filteredAdminLinks.count > 0 && (
                  <>{filteredAdminLinks.component}</>
                )}
              </>
            )}
          </Box>
          <List>
            <ListItem
              key={0}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <LogoutButton menuIsShowing={menuIsShowing} />
            </ListItem>
          </List>
        </Box>
      </Drawer>
      <Box
        sx={{
          flexGrow: 1,
          m: "15px",
          pt: "56px",
        }}
      >
        <Outlet />
      </Box>
    </Box>
  )
}

export default BaseLayout
