import { useCallback, useState } from "react"
import config from "../../../config"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Poll } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useGetPollById = () => {
  const { loading, setLoading, error, setError, authBearer } =
    useHandleRequest()
  const [data, setData] = useState<Poll>()

  const request = useCallback(
    (organizationId: number, pollId: number): Promise<Poll> => {
      return makeRequest<Poll>(`${config.apiUrl}/v1/polls/${pollId}`, {
        headers: {
          authorization: `Bearer ${authBearer}`,
          "Content-Type": "application/json",
          "X-Organization-Id": `${organizationId}`,
        },
      })
    },
    [authBearer]
  )

  const getPoll = useCallback(
    async (organizationId: number, pollId: number) => {
      setLoading(true)
      setError(null)
      let results
      try {
        results = await request(organizationId, pollId)
        setData(results)
      } catch (error) {
        setError(error)
      } finally {
        setLoading(false)
      }

      return results
    },
    [request, setLoading, setError]
  )

  return {
    loading,
    error,
    getPoll,
    poll: data,
  }
}

export default useGetPollById
