import { Button, FormControlLabel, Switch, TextField } from "@mui/material"
import { useFormik } from "formik"
import React, { FunctionComponent } from "react"
import toast from "react-hot-toast"
import * as Yup from "yup"
import Dialog from "../../../components/shared/Dialog"
import ErrorComponent from "../../../components/shared/Error"
import { School } from "../../../types"
import useCreateSchool from "../hooks/useCreateSchool"

interface Props {
  isOpen: boolean
  close: () => void
  school?: School | undefined
  onSuccess?: () => void
}

const SchoolsDialog: FunctionComponent<Props> = ({
  isOpen,
  close,
  school,
  onSuccess,
}) => {
  const { createSchool, error, loading } = useCreateSchool()

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    acronym: Yup.string().nullable().optional(),
    location: Yup.string().nullable().optional(),
    icon_url: Yup.string().nullable().optional(),
  })

  const handleClose = () => {
    close()
  }

  const formik = useFormik({
    initialValues: {
      name: null,
      acronym: null,
      location: null,
      icon_url: null,
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      const body = {
        name: values.name,
        acronym: values.acronym,
        location: values.location,
        icon_url: values.icon_url,
      }

      const createdSchool = await createSchool(body, handleCreateSuccess)
      if (createdSchool) {
        formik.resetForm()
        onSuccess && onSuccess()
      }
    },
  })

  const handleCreateSuccess = () => {
    toast.success(`Successfully created school`)
    close()
  }

  const handleUpdateSuccess = () => {
    toast.success(`Successfully updated school`)
    close()
  }

  React.useEffect(() => {
    if (school) {
      formik.setFieldValue("name", school.name)
      formik.setFieldValue("acronym", school.acronym)
      formik.setFieldValue("location", school.location)
      formik.setFieldValue("icon_url", school.icon_url)
    }
  }, [school])

  return (
    <Dialog
      title={school ? "Update School" : "Create School"}
      isOpen={isOpen}
      handleClose={handleClose}
      loading={loading}
      content={
        <>
          {error && <ErrorComponent error={error} />}
          <TextField
            margin="dense"
            id="name"
            name="name"
            label={"School Name"}
            fullWidth
            required
            variant="outlined"
            onChange={formik.handleChange}
            error={!!formik.errors.name}
            value={formik.values.name}
          />
          <TextField
            margin="dense"
            id="acronym"
            name="acronym"
            label={"Acronym"}
            fullWidth
            variant="outlined"
            onChange={formik.handleChange}
            error={!!formik.errors.acronym}
            value={formik.values.acronym}
          />
          <TextField
            margin="dense"
            id="location"
            name="location"
            label={"Location"}
            fullWidth
            variant="outlined"
            onChange={formik.handleChange}
            error={!!formik.errors.location}
            value={formik.values.location}
          />
          <TextField
            margin="dense"
            id="icon_url"
            name="icon_url"
            label={"Icon URL"}
            fullWidth
            variant="outlined"
            onChange={formik.handleChange}
            error={!!formik.errors.icon_url}
            value={formik.values.icon_url}
          />
        </>
      }
      buttonContent={
        <>
          <Button onClick={handleClose} color="error" variant="outlined">
            Close
          </Button>
          <Button
            onClick={formik.submitForm}
            color="primary"
            variant="outlined"
            disabled={loading}
          >
            {school ? "Update" : "Create"}
          </Button>
        </>
      }
    />
  )
}

export default SchoolsDialog
