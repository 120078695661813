import React, { FunctionComponent, useEffect, useState } from "react"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/ScreenContainer"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import { Title1 } from "../../components/shared/Typography"
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material"
import useGetMe from "./hooks/useGetMe"
import useUpdateProfilePhoto from "./hooks/useUploadProfilePhoto"
import AvatarEditor from "react-avatar-editor"
import useGetMeOrgMember from "./hooks/useGetMeOrgMember"

const AccountScreen: FunctionComponent = () => {
  const { getMe, loading, error, me } = useGetMe()
  const {
    getMeOrgMember,
    loading: meOrgLoading,
    error: meOrgError,
    meOrgMember,
  } = useGetMeOrgMember()
  const {
    updateProfilePhoto,
    loading: loadingPFP,
    error: errorPFP,
  } = useUpdateProfilePhoto()

  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [croppedImage, setCroppedImage] = useState<string | null>(null)
  const [scale, setScale] = useState<number>(1)

  useEffect(() => {
    getMe()
    getMeOrgMember()
    // eslint-disable-next-line
  }, [])

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]

    if (file) {
      setSelectedFile(file)
    }
  }

  const handleScaleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newScale = parseFloat(event.target.value)
    setScale(newScale)
  }

  const handleUpload = async () => {
    if (selectedFile) {
      const editor = editorRef.current
      if (editor) {
        const canvas = editor.getImageScaledToCanvas()
        const croppedImageUrl = canvas.toDataURL()
        setCroppedImage(croppedImageUrl)
        try {
          await updateProfilePhoto(croppedImageUrl, () => getMe())
        } catch (error) {
          // Handle error during profile photo update
          console.error("Error updating profile photo:", error)
        }
      }
    }
  }

  const editorRef = React.createRef<AvatarEditor>()

  if (loading || meOrgLoading) {
    return (
      <ScreenContainer>
        <Box
          display="flex"
          width="100%"
          sx={{
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            alignSelf: "center",
            mb: "24px",
          }}
        >
          <CircularProgress />
        </Box>
      </ScreenContainer>
    )
  }

  if (!me) {
    return <Title1>Not found</Title1>
  }

  return (
    <ScreenContainer>
      {error ? <ErrorComponent error={error} /> : null}
      {meOrgError ? <ErrorComponent error={meOrgError} /> : null}
      {loading || meOrgLoading ? <LoadingSpinner /> : null}
      <Card sx={{ minWidth: 275, margin: 3 }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Avatar
                alt={me.name}
                src={`${me.profile_photo}`}
                sx={{ width: 100, height: 100 }}
              />
              {errorPFP ? <ErrorComponent error={errorPFP} /> : null}
              <Typography variant="h4">{me.name}</Typography>
              <p>{me.email}</p>
              {meOrgMember && (
                <p>
                  {meOrgMember.points} Point
                  {meOrgMember.points === 1 ? "" : "s"}
                </p>
              )}
            </Grid>
          </Grid>
          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            style={{ display: "none" }}
            id="profile-photo-input"
          />
          <label htmlFor="profile-photo-input">
            <Button variant="contained" component="span">
              Upload Profile Photo
            </Button>
          </label>
          {selectedFile && (
            <div>
              <AvatarEditor
                ref={editorRef}
                image={selectedFile}
                width={200}
                height={200}
                border={50}
                color={[255, 255, 255, 0.6]} // RGBA
                scale={scale}
              />
              <div>
                <label htmlFor="scale">Zoom:</label>
                <input
                  id="scale"
                  type="range"
                  value={scale}
                  onChange={handleScaleChange}
                  min="1"
                  max="2"
                  step="0.01"
                />
              </div>
              <Button
                variant="contained"
                onClick={handleUpload}
                disabled={loadingPFP}
              >
                Upload Image
              </Button>
            </div>
          )}
        </CardContent>
      </Card>
    </ScreenContainer>
  )
}

export default AccountScreen
