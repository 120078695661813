import styled from "@emotion/styled"

// TODO: refactor this so it is more generic. border or 2px to support filled vs unfilled button seems hacky, should split this into a module with Button.Filled and Button.Outline or something along those lines

const Button = styled.button`
  padding: 16px 36px;
  border-radius: 8px;
  border: 2px solid;
  fontsize: 16px;
  cursor: pointer;
`

export default Button
