import React, { FunctionComponent, useEffect } from "react"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/ScreenContainer"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Typography,
} from "@mui/material"
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied"
import useGetMyHouses from "./hooks/useGetMyHouses"
import useGetHouseById from "../Organizations/hooks/useGetHouseById"
import { useNavigate } from "react-router-dom"

const HousesScreen: FunctionComponent = () => {
  const { getMyHouses, loading, error, houses } = useGetMyHouses()
  const { getHouseById } = useGetHouseById()

  const navigate = useNavigate()

  useEffect(() => {
    getMyHouses()
    // eslint-disable-next-line
  }, [])

  if (loading) {
    return (
      <ScreenContainer title="Your Organizations">
        <Box
          display="flex"
          width="100%"
          sx={{
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            alignSelf: "center",
            mb: "24px",
          }}
        >
          <CircularProgress />
        </Box>
      </ScreenContainer>
    )
  }

  const activeOrganizationId = localStorage.getItem("activeOrganizationId")
  const activeOrganizationIdNumber =
    activeOrganizationId && Number(activeOrganizationId)

  const setactiveOrganizationId = async (houseId: number) => {
    const newHouse = await getHouseById(houseId)
    localStorage.setItem("activeOrganizationId", `${houseId}`)
    localStorage.setItem("activeOrganization", JSON.stringify(newHouse))
    window.location.reload()
  }

  return (
    <ScreenContainer title="Your Organizations">
      {error ? <ErrorComponent error={error} /> : null}
      {loading ? <LoadingSpinner /> : null}
      {houses?.length === 0 ? (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "80vh",
            }}
          >
            <SentimentDissatisfiedIcon fontSize="large" />
            <Typography variant="h5">
              <b>No organizations found</b>
            </Typography>
            <Typography variant="body1">
              You're not apart of any organizations yet. <br />
              You can create one or enter an invite code to an existing
              organization!
            </Typography>
          </Box>
        </>
      ) : (
        <>
          {houses?.map((house) => {
            return (
              <Card
                sx={{
                  marginBottom: 2,
                  boxShadow: "none",
                  border: 1,
                  borderColor: "gray",
                }}
                key={house.id}
              >
                <CardContent
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    p: 2,
                    "&:last-child": { pb: 2 },
                  }}
                >
                  <Avatar
                    alt={house.name}
                    src={`${house.photo}`}
                    sx={{ width: 100, height: 100, marginRight: 2 }}
                    variant="rounded"
                  />
                  <div>
                    {house.name && (
                      <Typography variant="h6">
                        <b>{house.name}</b>
                        {house.id === activeOrganizationIdNumber && (
                          <Chip
                            label="Active"
                            sx={{
                              backgroundColor: "green",
                              color: "white",
                              borderRadius: 2,
                              marginRight: 0.5,
                              marginLeft: 1,
                              height: 25,
                            }}
                          />
                        )}
                      </Typography>
                    )}
                    {house.location && (
                      <Typography variant="subtitle1">
                        {house.location}
                      </Typography>
                    )}
                    {house.id !== activeOrganizationIdNumber && (
                      <Button
                        variant="contained"
                        onClick={() => setactiveOrganizationId(house.id)}
                      >
                        Set as active organization
                      </Button>
                    )}
                  </div>
                </CardContent>
              </Card>
            )
          })}
          <Box style={{ flex: 1, flexDirection: "row" }}>
            <Button
              variant="contained"
              onClick={() => navigate("/organizations/create")}
            >
              Create Organization
            </Button>{" "}
            <Button
              variant="contained"
              onClick={() => navigate("/organizations/join")}
            >
              Join Existing Organization
            </Button>
          </Box>
        </>
      )}
    </ScreenContainer>
  )
}

export default HousesScreen
