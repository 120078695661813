import { useCallback } from "react"
import config from "../../../config"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Me } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useUpdateProfilePhoto = () => {
  const { loading, setLoading, error, setError, authBearer } =
    useHandleRequest()

  const update = useCallback(
    (base64: string): Promise<Me> => {
      return makeRequest<Me>(`${config.apiUrl}/v1/users/me/profile-photo`, {
        method: "PUT",
        headers: {
          authorization: `Bearer ${authBearer}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ photo: base64 }),
      })
    },
    [authBearer]
  )

  const updateProfilePhoto = useCallback(
    async (base64: string, onSuccess: Function) => {
      setLoading(true)
      setError(null)
      let result
      try {
        result = await update(base64)
        if (onSuccess) {
          await onSuccess()
        }
      } catch (error) {
        setError(error)
      } finally {
        setError(null)
        setLoading(false)
      }

      return result
    },
    [update, setLoading, setError]
  )

  return { updateProfilePhoto, loading, error }
}

export default useUpdateProfilePhoto
