import { useCallback } from "react"
import config from "../../../config"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { OrganizationMember } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useUpdateOrganizationMember = () => {
  const { loading, setLoading, error, setError, authBearer } =
    useHandleRequest()

  const update = useCallback(
    (
      organizationId: number,
      organizationMemberId: number,
      body: object
    ): Promise<OrganizationMember> => {
      return makeRequest<OrganizationMember>(
        `${config.apiUrl}/v1/organizations/${organizationId}/members/${organizationMemberId}`,
        {
          method: "PUT",
          headers: {
            authorization: `Bearer ${authBearer}`,
            "Content-Type": "application/json",
            "X-Organization-Id": `${organizationId}`,
          },
          body: JSON.stringify({ ...body }),
        }
      )
    },
    [authBearer]
  )

  const updateOrganizationMember = useCallback(
    async (
      organizationId: number,
      organizationMemberId: number,
      body: object,
      onSuccess: Function
    ) => {
      setLoading(true)
      setError(null)
      let result
      try {
        result = await update(organizationId, organizationMemberId, body)
        if (onSuccess) {
          await onSuccess()
        }
      } catch (error) {
        setError(error)
      } finally {
        setError(null)
        setLoading(false)
      }

      return result
    },
    [update, setLoading, setError]
  )

  return { updateOrganizationMember, loading, error }
}

export default useUpdateOrganizationMember
