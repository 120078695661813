import { useCallback, useEffect, useState } from "react"
import config from "../../../config"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { OrganizationMember } from "../../../types"
import makeRequest, { PagedResponse } from "../../../utils/make-request"

const useGetHouseMembers = (defaultRowsPerPage = 25) => {
  const { loading, setLoading, error, setError, authBearer } =
    useHandleRequest()
  const [data, setData] = useState<PagedResponse<OrganizationMember>>()

  const [houseId, setHouseId] = useState<number>()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage)

  const handleChangePage = (event: any, page: number) => {
    setPage(page)
  }

  const handleChangeRowsPerPage = (event: any) => {
    setPage(0)
    setRowsPerPage(event.target.value)
  }

  useEffect(() => {
    if (houseId) {
      getHouseMembers(houseId)
    }
    // eslint-disable-next-line
  }, [page, rowsPerPage])

  const request = useCallback(
    (houseId: number): Promise<PagedResponse<OrganizationMember>> => {
      let queryParams = `limit=${rowsPerPage}&offset=${rowsPerPage * page}`
      return makeRequest<PagedResponse<OrganizationMember>>(
        `${config.apiUrl}/v1/organizations/${houseId}/members?${queryParams}`,
        {
          headers: {
            authorization: `Bearer ${authBearer}`,
            "X-Organization-Id": `${houseId}`,
          },
        }
      )
    },
    [authBearer, page, rowsPerPage]
  )

  const getHouseMembers = useCallback(
    async (houseId: number) => {
      setHouseId(houseId)
      setLoading(true)
      setError(null)
      let results
      try {
        results = await request(houseId)
        setData(results)
      } catch (error) {
        setError(error)
      } finally {
        setLoading(false)
      }

      return results
    },
    [request, setLoading, setError]
  )

  return {
    loading,
    error,
    getHouseMembers,
    members: data?.data || [],
    paginationData: {
      count: data?.pagination?.total_count || 0,
      page,
      rowsPerPage,
      onPageChange: handleChangePage,
      onRowsPerPageChange: handleChangeRowsPerPage,
    },
  }
}

export default useGetHouseMembers
