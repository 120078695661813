/* eslint-disable no-template-curly-in-string */
interface AppConfig {
  environmentName: string
  apiUrl: string
  assetsBase: string
  mapkitToken: string
  intercomAppId: string
  mapsAPIKey: string
}

const localConfig: AppConfig = {
  environmentName: "local",
  apiUrl: "https://api-staging.greekchapter.com",
  assetsBase: "https://assets-staging.greekchapter.com",
  mapkitToken:
    "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6Iko0MjhRNkxDQTUifQ.eyJpc3MiOiIyWTlUOEJUSkdUIiwiaWF0IjoxNzEzMDI3OTc5LCJleHAiOjE3NDQ1MDI0MDAsIm9yaWdpbiI6Imh0dHBzOi8vd2ViLXN0YWdpbmcuZ3JlZWtjaGFwdGVyLmNvbSwgaHR0cDovL2xvY2FsaG9zdDozMDAwIn0",
  intercomAppId: "tvug20o3",
  mapsAPIKey: "AIzaSyBvD0pdabDUD7NKK9fdUQqjk9KNzLKKj0c"
}

const remoteConfig: AppConfig = {
  environmentName: "${REACT_APP_ENVIRONMENT_NAME}",
  apiUrl: "${REACT_APP_API_URL}",
  assetsBase: "${REACT_APP_ASSETS_BASE}",
  mapkitToken: "${REACT_APP_MAPKIT_TOKEN}",
  intercomAppId: "${REACT_APP_INTERCOM_APP_ID}",
  mapsAPIKey: "${REACT_APP_MAPS_API_KEY}"
}

const config: AppConfig =
  process.env.REACT_APP_LOCAL === "true" ? localConfig : remoteConfig

export default config
