import React, { FunctionComponent } from "react"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import { School } from "../../../types"

type Props = {
  school: School
  refreshData: () => void
}

const SchoolsRow: FunctionComponent<Props> = ({ school, refreshData }) => {
  return (
    <>
      <BasicTableRow key={school.id}>
        <BasicTableCell component="th" scope="row">
          {school.id}
        </BasicTableCell>
        <BasicTableCell component="th" scope="row">
          <img
            src={school.icon_url}
            alt={school.name}
            style={{ height: 25, width: 25 }}
          />
        </BasicTableCell>
        <BasicTableCell component="th" scope="row">
          {school.name}
        </BasicTableCell>
        <BasicTableCell component="th" scope="row">
          {school.acronym}
        </BasicTableCell>
        <BasicTableCell component="th" scope="row">
          {school.location}
        </BasicTableCell>
      </BasicTableRow>
    </>
  )
}

export default SchoolsRow
