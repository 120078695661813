import { useCallback, useEffect, useState } from "react"
import config from "../../../config"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Announcement } from "../../../types"
import makeRequest, { PagedResponse } from "../../../utils/make-request"

const useGetAnnouncements = (defaultRowsPerPage = 25) => {
  const { loading, setLoading, error, setError, authBearer } =
    useHandleRequest()
  const [data, setData] = useState<PagedResponse<Announcement>>()

  const [houseId, setHouseId] = useState<number>()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage)

  const handleChangePage = (event: any, page: number) => {
    setPage(page)
  }

  const handleChangeRowsPerPage = (event: any) => {
    setPage(0)
    setRowsPerPage(event.target.value)
  }

  useEffect(() => {
    if (houseId) {
      getAnnouncements(houseId)
    }
    // eslint-disable-next-line
  }, [page, rowsPerPage])

  const request = useCallback(
    (
      houseId: number,
      active?: boolean
    ): Promise<PagedResponse<Announcement>> => {
      let queryParams = `limit=${rowsPerPage}&offset=${rowsPerPage * page}`
      if (active !== undefined) {
        queryParams += `&active=${active}`
      }
      return makeRequest<PagedResponse<Announcement>>(
        `${config.apiUrl}/v1/organizations/${houseId}/announcements?${queryParams}`,
        {
          headers: {
            authorization: `Bearer ${authBearer}`,
            "X-Organization-Id": `${houseId}`,
          },
        }
      )
    },
    [authBearer, page, rowsPerPage]
  )

  const getAnnouncements = useCallback(
    async (houseId: number, active?) => {
      setHouseId(houseId)
      setLoading(true)
      setError(null)
      let results
      try {
        results = await request(houseId, active)
        setData(results)
      } catch (error) {
        setError(error)
      } finally {
        setLoading(false)
      }

      return results
    },
    [request, setLoading, setError]
  )

  return {
    loading,
    error,
    getAnnouncements,
    announcements: data?.data || [],
    paginationData: {
      count: data?.pagination?.total_count || 0,
      page,
      rowsPerPage,
      onPageChange: handleChangePage,
      onRowsPerPageChange: handleChangeRowsPerPage,
    },
  }
}

export default useGetAnnouncements
