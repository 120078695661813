import { Button, FormLabel, Switch, TextField } from "@mui/material"
import { useFormik } from "formik"
import React, { FunctionComponent } from "react"
import toast from "react-hot-toast"
import * as Yup from "yup"
import Dialog from "../../../components/shared/Dialog"
import ErrorComponent from "../../../components/shared/Error"
import useCreateInvite from "../hooks/useCreateInvite"

interface Props {
  isOpen: boolean
  close: () => void
}

const CreateInviteDialog: FunctionComponent<Props> = ({ isOpen, close }) => {
  const { createInvite, invite, error, loading } = useCreateInvite()

  const validationSchema = Yup.object().shape({
    unlimited_uses: Yup.boolean().default(false).required(),
    max_usages: Yup.number().integer().optional(),
  })

  const handleClose = () => {
    formik.resetForm()
    close()
  }

  const formik = useFormik({
    initialValues: {
      unlimited_uses: false,
      max_usages: 1,
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      const houseId = localStorage.getItem("activeOrganizationId")
      if (houseId) {
        await createInvite(
          Number(houseId),
          values.unlimited_uses,
          values.max_usages,
          handleCreateSuccess
        )
      }
    },
  })

  const handleCreateSuccess = () => {
    toast.success("Successfully created invite code")
  }

  return (
    <Dialog
      title={!invite ? "Create Invite Code" : "Invite Code"}
      isOpen={isOpen}
      handleClose={handleClose}
      loading={loading}
      content={
        <>
          {error && <ErrorComponent error={error} />}
          {!invite ? (
            <>
              <FormLabel>Unlimited uses</FormLabel>
              <Switch
                id="unlimited_uses"
                name="unlimited_uses"
                checked={formik.values.unlimited_uses}
                onChange={formik.handleChange}
                color="primary"
              />
              {!formik.values.unlimited_uses ? (
                <TextField
                  margin="dense"
                  id="max_usages"
                  name="max_usages"
                  label="Max Usages"
                  fullWidth
                  variant="outlined"
                  type="number"
                  onChange={formik.handleChange}
                  value={formik.values.max_usages}
                />
              ) : null}
            </>
          ) : (
            <>
              <h1>
                <b>Invite code:</b> {invite.code}
              </h1>
            </>
          )}
        </>
      }
      buttonContent={
        <>
          <Button onClick={handleClose} color="error" variant="outlined">
            Close
          </Button>
          {!invite && (
            <Button
              onClick={formik.submitForm}
              color="primary"
              variant="outlined"
              disabled={loading}
            >
              Create Invite Code
            </Button>
          )}
        </>
      }
    />
  )
}

export default CreateInviteDialog
