import { useCallback, useState } from "react"
import config from "../../../config"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { UnreadNotificationCount } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useGetMyUnreadNotificationCount = () => {
  const { loading, setLoading, error, setError, authBearer } =
    useHandleRequest()
  const [data, setData] = useState<UnreadNotificationCount>()

  const request = useCallback((): Promise<UnreadNotificationCount> => {
    return makeRequest<UnreadNotificationCount>(
      `${config.apiUrl}/v1/users/me/notifications/unread/count`,
      {
        headers: {
          authorization: `Bearer ${authBearer}`,
        },
      }
    )
  }, [authBearer])

  const getUnreadNotificationCount = useCallback(async () => {
    setLoading(true)
    setError(null)
    let results
    try {
      results = await request()
      setData(results)
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }

    return results
  }, [request, setLoading, setError])

  return {
    loading,
    error,
    getUnreadNotificationCount,
    unreadCount: data,
  }
}

export default useGetMyUnreadNotificationCount
