import { styled } from "@mui/material/styles"

const getTextColor = ({ theme, hasError, hasWarning }: any) => {
  if (hasError) {
    return theme.palette.error.main
  } else if (hasWarning) {
    return theme.palette.warning.main
  } else {
    return theme.palette.text.primary
  }
}

const LargeTitle = styled("h1")`
  font-size: 34px;
  line-height: 41px;
  letter-spacing: 0.0120588em;
  margin: 0px;
  color: ${({ theme }) => theme.palette.text.primary};
`

const Title1 = styled("h2")`
  font-size: 28px;
  line-height: 34px;
  letter-spacing: 0.0128571em;
  margin: 0px;
  color: ${({ theme }) => theme.palette.text.primary};
`

const Title2 = styled("h3")`
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0.0145455em;
  margin: 0px;
  color: ${({ theme }) => theme.palette.text.primary};
`

const Title3 = styled("h4")`
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.019em;
  margin: 0px;
  color: ${({ theme }) => theme.palette.text.primary};
`

const Headline = styled("h5")`
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.0241176em;
  margin: 0px;
  color: ${({ theme }) => theme.palette.text.primary};
`

const Callout = styled("h6")`
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.02em;
  margin: 0px;
  color: ${({ theme }) => theme.palette.text.primary};
`

const Subhead = styled("h6", {
  shouldForwardProp: (prop) => prop !== "hasError" && prop !== "hasWarning",
})`
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.016em;
  margin: 0px;
  color: ${({ theme, hasError }: any) =>
    hasError ? theme.palette.error.main : theme.palette.text.primary};
`

const TextBody: any = styled("p", {
  shouldForwardProp: (prop) => prop !== "hasError" && prop !== "hasWarning",
})`
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.0241176em;
  margin: 0px;
  color: ${getTextColor};
`

const Footnote = styled("p")`
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.00615385em;
  margin: 0px;
  color: ${({ theme }) => theme.palette.text.primary};
`

const Caption1: any = styled("p", {
  shouldForwardProp: (prop) => prop !== "hasError" && prop !== "hasWarning",
})`
  font-size: 12px;
  line-height: 16px;
  margin: 0px;
  color: ${({ theme, hasError }: any) =>
    hasError ? theme.palette.error.main : theme.palette.text.primary};
`

const Caption2: any = styled("p", {
  shouldForwardProp: (prop) => prop !== "hasError" && prop !== "hasWarning",
})`
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.00636364em;
  margin: 0px;
  color: ${({ theme, hasError }: any) =>
    hasError ? theme.palette.error.main : theme.palette.text.primary};
`

export {
  LargeTitle,
  Title1,
  Title2,
  Title3,
  Headline,
  Callout,
  Subhead,
  TextBody,
  Footnote,
  Caption1,
  Caption2,
}
