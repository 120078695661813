import { Box, Button, CircularProgress, Grid } from "@mui/material"
import React, { FunctionComponent, useEffect } from "react"
import ScreenContainer from "../../components/shared/ScreenContainer"
import useGetMyHouses from "../Users/hooks/useGetMyHouses"
import { useNavigate } from "react-router-dom"

const WelcomeScreen: FunctionComponent = () => {
  const { getMyHouses, loading, error } = useGetMyHouses()

  const navigate = useNavigate()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userOrganizations = await getMyHouses()

        if (userOrganizations) {
          if (userOrganizations.length === 0) {
            // we're in the correct place
          }

          if (userOrganizations.length > 0) {
            localStorage.setItem(
              "activeOrganizationId",
              `${userOrganizations[0].id}`
            )
            localStorage.setItem(
              "activeOrganization",
              JSON.stringify(userOrganizations[0])
            )
            navigate("/")
          }
        }
      } catch (err) {
        console.error(`Error loading organizations: ${error}`)
      }
    }

    fetchData()

    // eslint-disable-next-line
  }, [])

  if (loading) {
    return (
      <ScreenContainer>
        <Box
          display="flex"
          width="100%"
          sx={{
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            alignSelf: "center",
            mb: "24px",
          }}
        >
          <CircularProgress />
        </Box>
      </ScreenContainer>
    )
  }

  return (
    <ScreenContainer
      title={"Welcome to Greek Chapter"}
      subtitle={
        "We're so happy to have you here! If you're wanting to set up your organization with Greek Chapter for the first time, you can do that below. If you are looking to join your existing organization on Greek Chapter, you can also do that below."
      }
    >
      <Grid container spacing={3} direction="column" alignItems="center">
        <Grid item xs={12}>
          <Button
            variant="contained"
            sx={{ textTransform: "none", width: "80%", marginBottom: "20px" }}
            onClick={() => navigate("/organizations/create")}
          >
            I'm setting up my chapter on Greek Chapter for the first time
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            sx={{ textTransform: "none", width: "80%" }}
            onClick={() => navigate("/organizations/join")}
          >
            My chapter is already on Greek Chapter
          </Button>
        </Grid>
      </Grid>
    </ScreenContainer>
  )
}

export default WelcomeScreen
