import { Button, FormControlLabel, Switch, TextField } from "@mui/material"
import { useFormik } from "formik"
import React, { FunctionComponent } from "react"
import toast from "react-hot-toast"
import * as Yup from "yup"
import Dialog from "../../../components/shared/Dialog"
import ErrorComponent from "../../../components/shared/Error"
import { AttendanceItem } from "../../../types"
import useCreateAttendanceItem from "../hooks/useCreateAttendanceItem"

interface Props {
  isOpen: boolean
  close: () => void
  attendanceItem?: AttendanceItem | undefined
  onSuccess?: () => void
}

const AttendanceItemDialog: FunctionComponent<Props> = ({
  isOpen,
  close,
  attendanceItem,
  onSuccess,
}) => {
  const { createAttendanceItem, error, loading } = useCreateAttendanceItem()

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(),
    description: Yup.string().nullable().optional(),
  })

  const handleClose = () => {
    close()
  }

  const formik = useFormik({
    initialValues: {
      title: "",
      description: null,
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      const houseId = localStorage.getItem("activeOrganizationId")
      if (houseId) {
        if (attendanceItem) {
          //   const body = {
          //     name: values.name,
          //     description: values.description,
          //     max_guests: values.max_guests,
          //     is_visible: values.is_visible,
          //     editing_enabled: values.editing_enabled,
          //   }
          //   const updatedGuestList = await updateGuestList(
          //     Number(houseId),
          //     Number(attendanceItem.id),
          //     body,
          //     handleUpdateSuccess
          //   )
          //   if (updatedGuestList) {
          //     onSuccess && onSuccess()
          //   }
        } else {
          const body = {
            title: values.title,
            description: values.description,
          }
          const createdAttendanceItem = await createAttendanceItem(
            Number(houseId),
            body,
            handleCreateSuccess
          )
          if (createdAttendanceItem) {
            formik.resetForm()
            onSuccess && onSuccess()
          }
        }
      }
    },
  })

  const handleCreateSuccess = () => {
    toast.success(`Successfully created attendance item`)
    close()
  }

  const handleUpdateSuccess = () => {
    toast.success(`Successfully updated attendance item`)
    close()
  }

  React.useEffect(() => {
    if (attendanceItem) {
      formik.setFieldValue("title", attendanceItem.title)
    }
  }, [attendanceItem])

  const today = new Date()
  const day = today.getDay()
  const diff = today.getDate() - day + (day == 0 ? -6 : 1) // adjust when day is sunday
  const monday = new Date(today.setDate(diff))
  const mondayDate = monday.getDate()
  const mondayMonth = monday.getMonth() + 1
  const mondayYear = monday.getFullYear()
  const mondayFormatted = `${mondayMonth}/${mondayDate}/${mondayYear}`

  return (
    <Dialog
      title={
        attendanceItem ? "Update Attendance Item" : "Create Attendance Item"
      }
      isOpen={isOpen}
      handleClose={handleClose}
      loading={loading}
      content={
        <>
          {error && <ErrorComponent error={error} />}
          {/* {updateError && <ErrorComponent error={updateError} />} */}
          <TextField
            margin="dense"
            id="title"
            name="title"
            label={"Attendance Item Title"}
            placeholder={`Chapter Meeting ${mondayFormatted}`}
            fullWidth
            required
            variant="outlined"
            onChange={formik.handleChange}
            error={!!formik.errors.title}
            value={formik.values.title}
          />
          <TextField
            margin="dense"
            id="description"
            name="description"
            label={"Description"}
            placeholder="Description"
            fullWidth
            variant="outlined"
            onChange={formik.handleChange}
            error={!!formik.errors.description}
            value={formik.values.description}
          />
        </>
      }
      buttonContent={
        <>
          <Button onClick={handleClose} color="error" variant="outlined">
            Close
          </Button>
          <Button
            onClick={formik.submitForm}
            color="primary"
            variant="outlined"
            disabled={loading}
          >
            {attendanceItem ? "Update" : "Create"}
          </Button>
        </>
      }
    />
  )
}

export default AttendanceItemDialog
