import { Button, FormControlLabel, Switch, TextField } from "@mui/material"
import { useFormik } from "formik"
import React, { FunctionComponent } from "react"
import toast from "react-hot-toast"
import * as Yup from "yup"
import Dialog from "../../../components/shared/Dialog"
import ErrorComponent from "../../../components/shared/Error"
import { GuestList } from "../../../types"
import useCreateGuestList from "../hooks/useCreateGuestList"
import useUpdateGuestList from "../hooks/useUpdateGuestList"

interface Props {
  isOpen: boolean
  close: () => void
  guestList?: GuestList | undefined
  onSuccess?: () => void
}

const GuestListDialog: FunctionComponent<Props> = ({
  isOpen,
  close,
  guestList,
  onSuccess,
}) => {
  const { createGuestList, error, loading } = useCreateGuestList()
  const {
    updateGuestList,
    error: updateError,
    loading: updateLoading,
  } = useUpdateGuestList()

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    description: Yup.string().nullable().optional(),
    max_guests: Yup.number().min(0).required(),
    is_visible: Yup.boolean().required(),
    editing_enabled: Yup.boolean().required(),
  })

  const handleClose = () => {
    close()
  }

  const formik = useFormik({
    initialValues: {
      name: "",
      description: null,
      max_guests: 1,
      is_visible: true,
      editing_enabled: true,
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      const houseId = localStorage.getItem("activeOrganizationId")
      if (houseId) {
        if (guestList) {
          const body = {
            name: values.name,
            description: values.description,
            max_guests: values.max_guests,
            is_visible: values.is_visible,
            editing_enabled: values.editing_enabled,
          }
          const updatedGuestList = await updateGuestList(
            Number(houseId),
            Number(guestList.id),
            body,
            handleUpdateSuccess
          )
          if (updatedGuestList) {
            onSuccess && onSuccess()
          }
        } else {
          const body = {
            name: values.name,
            description: values.description,
            max_guests: values.max_guests,
            is_visible: values.is_visible,
            editing_enabled: values.editing_enabled,
          }
          const createdGuestList = await createGuestList(
            Number(houseId),
            body,
            handleCreateSuccess
          )
          if (createdGuestList) {
            formik.resetForm()
            onSuccess && onSuccess()
          }
        }
      }
    },
  })

  const handleCreateSuccess = () => {
    toast.success(`Successfully created guest list`)
    close()
  }

  const handleUpdateSuccess = () => {
    toast.success(`Successfully updated guest list`)
    close()
  }

  React.useEffect(() => {
    if (guestList) {
      formik.setFieldValue("name", guestList.name)
      formik.setFieldValue("description", guestList.description)
      formik.setFieldValue("max_guests", guestList.max_guests)
      formik.setFieldValue("is_visible", guestList.is_visible)
      formik.setFieldValue("editing_enabled", guestList.editing_enabled)
    }
  }, [guestList])

  return (
    <Dialog
      title={guestList ? "Update Guest List" : "Create Guest List"}
      isOpen={isOpen}
      handleClose={handleClose}
      loading={loading}
      content={
        <>
          {error && <ErrorComponent error={error} />}
          {/* {updateError && <ErrorComponent error={updateError} />} */}
          <TextField
            margin="dense"
            id="name"
            name="name"
            label={"Guest List Name"}
            placeholder="Date party 06/27/2024"
            fullWidth
            required
            variant="outlined"
            onChange={formik.handleChange}
            error={!!formik.errors.name}
            value={formik.values.name}
          />
          <TextField
            margin="dense"
            id="max_guests"
            name="max_guests"
            label="Max Guests"
            placeholder="Number of guests each member can bring"
            required
            fullWidth
            type="number"
            variant="outlined"
            onChange={formik.handleChange}
            error={!!formik.errors.max_guests}
            value={formik.values.max_guests}
          />
          <FormControlLabel
            control={
              <Switch
                checked={formik.values.is_visible}
                onChange={formik.handleChange}
                name="is_visible"
              />
            }
            label="Visible (members can see the list)"
          />
          <FormControlLabel
            control={
              <Switch
                checked={formik.values.editing_enabled}
                onChange={formik.handleChange}
                name="editing_enabled"
              />
            }
            label="Editing Enabled (members can add/remove guests on the list)"
          />
        </>
      }
      buttonContent={
        <>
          <Button onClick={handleClose} color="error" variant="outlined">
            Close
          </Button>
          <Button
            onClick={formik.submitForm}
            color="primary"
            variant="outlined"
            disabled={loading}
          >
            {guestList ? "Update" : "Create"}
          </Button>
        </>
      }
    />
  )
}

export default GuestListDialog
