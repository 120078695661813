import { Button, TextField, Typography } from "@mui/material"
import { useFormik } from "formik"
import React, { FunctionComponent } from "react"
import toast from "react-hot-toast"
import * as Yup from "yup"
import Dialog from "../../../components/shared/Dialog"
import ErrorComponent from "../../../components/shared/Error"
import useUpdateDefaultStudyHours from "../hooks/useUpdateDefaultStudyHours"

interface Props {
  isOpen: boolean
  close: () => void
  currentDefault: number
  onSuccess?: () => void
}

const EditDefaultStudyHoursDialog: FunctionComponent<Props> = ({
  isOpen,
  close,
  currentDefault,
  onSuccess,
}) => {
  const { updateDefaultStudyHours, error, loading } =
    useUpdateDefaultStudyHours()

  const validationSchema = Yup.object().shape({
    hours: Yup.number()
      .integer()
      .required("Hours is required and must be a full number"),
  })

  const handleClose = () => {
    close()
  }

  const formik = useFormik({
    initialValues: {
      hours: currentDefault,
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      const organizationId = localStorage.getItem("activeOrganizationId")
      if (organizationId) {
        const updatedHours = await updateDefaultStudyHours(
          Number(organizationId),
          values.hours,
          handleCreateSuccess
        )
        if (updatedHours) {
          formik.resetForm()
          onSuccess && onSuccess()
        }
      }
    },
  })

  const handleCreateSuccess = () => {
    toast.success(
      `Successfully updated the default study hours for ${formik.values.hours}!`
    )
    close()
  }

  return (
    <Dialog
      title={"Update Default Study Hours"}
      isOpen={isOpen}
      handleClose={handleClose}
      loading={loading}
      content={
        <>
          {error && <ErrorComponent error={error} />}
          <TextField
            margin="dense"
            id="hours"
            name="hours"
            label={"Default Study Hours"}
            fullWidth
            required
            variant="outlined"
            type="number"
            onChange={formik.handleChange}
            error={!!formik.errors.hours}
            value={formik.values.hours}
          />
          <Typography>
            This should be used as a base for all members within the
            organization. Please note that if members have a custom amount of
            required study hours set,{" "}
            <b>this will override all custom required hours</b> and cannot be
            undone. Please ensure this is what you would like to do before
            saving.
          </Typography>
        </>
      }
      buttonContent={
        <>
          <Button onClick={handleClose} color="error" variant="outlined">
            Close
          </Button>
          <Button
            onClick={formik.submitForm}
            color="primary"
            variant="outlined"
            disabled={loading}
          >
            Save Changes
          </Button>
        </>
      }
    />
  )
}

export default EditDefaultStudyHoursDialog
