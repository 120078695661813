import { useCallback, useState } from "react"
import config from "../../../config"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { AttendanceItem } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useGetAttendanceItemById = () => {
  const { loading, setLoading, error, setError, authBearer } =
    useHandleRequest()
  const [data, setData] = useState<AttendanceItem>()

  const request = useCallback(
    (
      organizationId: number,
      attendanceItemId: number
    ): Promise<AttendanceItem> => {
      return makeRequest<AttendanceItem>(
        `${config.apiUrl}/v1/attendance/items/${attendanceItemId}`,
        {
          headers: {
            authorization: `Bearer ${authBearer}`,
            "Content-Type": "application/json",
            "X-Organization-Id": `${organizationId}`,
          },
        }
      )
    },
    [authBearer]
  )

  const getAttendanceItem = useCallback(
    async (organizationId: number, attendanceItemId: number) => {
      setLoading(true)
      setError(null)
      let results
      try {
        results = await request(organizationId, attendanceItemId)
        setData(results)
      } catch (error) {
        setError(error)
      } finally {
        setLoading(false)
      }

      return results
    },
    [request, setLoading, setError]
  )

  return {
    loading,
    error,
    getAttendanceItem,
    attendanceItem: data,
  }
}

export default useGetAttendanceItemById
