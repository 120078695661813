import {
  Autocomplete,
  Box,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material"
import React, { FunctionComponent, useEffect, useState } from "react"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/ScreenContainer"
import useGetHouseById from "../Organizations/hooks/useGetHouseById"
import useGetHouseMembers from "../Organizations/hooks/useGetHouseMembers"
import MemberPointsTable from "./components/MemberPointsTable"
import { scopes } from "../../scopes"
import { useAuth } from "../../services/auth-service"
import styles from "./PointsScreen.module.css"
import AddButton from "../../components/shared/AddButton"
import CreatePointItemDialog from "./components/CreatePointItemDialog"
import { Term } from "../../types"
import useGetOrganizationTerms from "../Organizations/hooks/useGetOrganizationTerms"

const PointsScreen: FunctionComponent = () => {
  const {
    getHouseById,
    house,
    loading: houseLoading,
    error: houseError,
  } = useGetHouseById()
  const {
    getHouseMembers,
    paginationData,
    members,
    loading: membersLoading,
    error: membersError,
  } = useGetHouseMembers()
  const { hasScope } = useAuth()
  const [createPointsOpen, setCreatePointsOpen] = useState<boolean>(false)
  const [selectedTermID, setSelectedTermID] = useState<number>()

  const {
    terms,
    getOrganizationTerms,
    loading: termsLoading,
  } = useGetOrganizationTerms()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const houseId = localStorage.getItem("activeOrganizationId")

        if (houseId) {
          await getHouseById(Number(houseId))
          await getOrganizationTerms(Number(houseId))

          if (house) {
            localStorage.setItem("activeOrganization", JSON.stringify(house))
          }
        }
      } catch (err) {
        console.error(`Error loading organization: ${houseError}`)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    const fetchMembers = async () => {
      if (house?.id) {
        await getHouseMembers(house.id)
      }
    }
    fetchMembers()
  }, [selectedTermID, house?.id]) // Fetch members when selectedTermID or house ID changes

  const canCreatePoints = hasScope(scopes.organization.member.points.create)

  const createPointsSuccess = () => {
    setCreatePointsOpen(false)
    getHouseMembers(house?.id!)
  }

  if (houseLoading) {
    return (
      <ScreenContainer>
        <Box
          display="flex"
          width="100%"
          sx={{
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            alignSelf: "center",
            mb: "24px",
          }}
        >
          <CircularProgress />
        </Box>
      </ScreenContainer>
    )
  }

  return (
    <ScreenContainer requiresScope={scopes.organization.pointItems.read}>
      <Box
        display="flex"
        width="100%"
        sx={{
          flexDirection: "column",
          mb: "24px",
        }}
        className={styles.pointsScreenContainer}
      >
        {membersError && <ErrorComponent error={membersError} />}
        {membersLoading && <CircularProgress />}
        <Typography variant="h3" className={styles.title}>
          Points Management
        </Typography>
        <Autocomplete
          key={terms ? `term-${house?.term?.id}` : "emptyTerm"}
          options={terms || []}
          value={
            selectedTermID
              ? terms?.find((term) => term.id === selectedTermID)
              : terms?.find((term) => term.id === house?.term?.id)
          }
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={(event, newValue) => {
            setSelectedTermID((newValue as Term).id)
          }}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Term"
              variant="outlined"
              InputProps={{ ...params.InputProps, readOnly: true }} // Make the input field read-only
            />
          )}
          sx={{ width: "100%", marginTop: -2, marginBottom: 2, maxWidth: 300 }}
          disabled={termsLoading || houseLoading || membersLoading}
        />
        <MemberPointsTable
          members={members}
          paginationData={paginationData}
          refreshData={() => getHouseMembers(house?.id!)}
          termID={selectedTermID || house?.term?.id}
        />
      </Box>
      {canCreatePoints && (
        <Box
          className={styles.createPointItemButtonContainer}
          onClick={() => setCreatePointsOpen(true)}
        >
          <AddButton />
        </Box>
      )}
      <CreatePointItemDialog
        isOpen={createPointsOpen}
        close={() => setCreatePointsOpen(false)}
        onSuccess={() => createPointsSuccess()}
      />
    </ScreenContainer>
  )
}

export default PointsScreen
