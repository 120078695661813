import { Box, IconButton, SvgIcon } from "@mui/material"
import React, { FunctionComponent, ReactNode } from "react"
import { LargeTitle, TextBody } from "./Typography"
import { useAuth } from "../../services/auth-service"
import ForbiddenPage from "./ForbiddenPage"

interface ScreenProps {
  title?: string
  subtitle?: string
  children?: ReactNode
  requiresScope?: string
  icon?: React.ReactElement<typeof SvgIcon>
  iconAction?: () => void
}

const ScreenContainer: FunctionComponent<ScreenProps> = ({
  title,
  subtitle,
  children,
  requiresScope,
  icon,
  iconAction,
}) => {
  const { hasScope } = useAuth()

  if (!!requiresScope && !hasScope(requiresScope)) {
    return <ForbiddenPage />
  } else {
    return (
      <Box sx={{ flexDirection: "column" }}>
        {!!title && (
          <>
            <Box sx={{ display: "flex", alignItems: "center", maxWidth: "100vw" }}>
              <LargeTitle>{title}</LargeTitle>
              {icon && iconAction && (
                <IconButton onClick={iconAction}>{icon}</IconButton>
              )}
            </Box>
            <TextBody>{subtitle}</TextBody>
          </>
        )}

        <Box display="flex" sx={{ flexDirection: "column", flex: 1 }}>
          {children}
        </Box>
      </Box>
    )
  }
}

export default ScreenContainer
