import { useCallback, useState } from "react"
import config from "../../../config"
import useHandleRequest from "../../../hooks/useHandleRequest"
import makeRequest from "../../../utils/make-request"
import { Poll } from "../../../types"

const useEndPollById = () => {
  const { loading, setLoading, error, setError, authBearer } =
    useHandleRequest()
  const [data, setData] = useState<Poll>()

  const request = useCallback(
    (organizationId: number, pollId: number): Promise<Poll> => {
      return makeRequest<Poll>(`${config.apiUrl}/v1/polls/${pollId}/end`, {
        method: "POST",
        headers: {
          authorization: `Bearer ${authBearer}`,
          "Content-Type": "application/json",
          "X-Organization-Id": `${organizationId}`,
        },
      })
    },
    [authBearer]
  )

  const endPoll = useCallback(
    async (organizationId: number, pollId: number, onSuccess: Function) => {
      setLoading(true)
      setError(null)
      let results
      try {
        results = await request(organizationId, pollId)
        setData(results)
        if (onSuccess) {
          await onSuccess()
        }
      } catch (error) {
        setError(error)
      } finally {
        setLoading(false)
      }

      return results
    },
    [request, setLoading, setError]
  )

  return {
    loading,
    error,
    endPoll,
    poll: data,
  }
}

export default useEndPollById
