import { useCallback, useState } from "react"
import config from "../../../config"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { OrganizationMember } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useGetMeOrgMember = () => {
  const { loading, setLoading, error, setError, authBearer } =
    useHandleRequest()
  const [data, setData] = useState<OrganizationMember>()

  const houseId = localStorage.getItem("activeOrganizationId")
  const houseIdNumber = (houseId && Number(houseId)) || null

  const request = useCallback((): Promise<OrganizationMember> => {
    return makeRequest<OrganizationMember>(`${config.apiUrl}/v1/users/me-org`, {
      headers: {
        authorization: `Bearer ${authBearer}`,
        "X-Organization-Id": `${houseIdNumber}`,
      },
    })
  }, [authBearer])

  const getMeOrgMember = useCallback(async () => {
    setLoading(true)
    setError(null)
    let results
    try {
      results = await request()
      setData(results)
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }

    return results
  }, [request, setLoading, setError])

  return {
    loading,
    error,
    getMeOrgMember,
    meOrgMember: data,
  }
}

export default useGetMeOrgMember
