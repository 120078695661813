import { useCallback, useState } from "react"
import config from "../../../config"
import useHandleRequest from "../../../hooks/useHandleRequest"
import makeRequest from "../../../utils/make-request"
import { Poll } from "../../../types"

const useVoteInPollById = () => {
  const { loading, setLoading, error, setError, authBearer } =
    useHandleRequest()
  const [data, setData] = useState<Poll>()

  const request = useCallback(
    (
      organizationId: number,
      pollId: number,
      choiceIds: number[]
    ): Promise<Poll> => {
      return makeRequest<Poll>(`${config.apiUrl}/v1/polls/${pollId}/vote`, {
        method: "POST",
        headers: {
          authorization: `Bearer ${authBearer}`,
          "Content-Type": "application/json",
          "X-Organization-Id": `${organizationId}`,
        },
        body: JSON.stringify({ choice_ids: choiceIds }),
      })
    },
    [authBearer]
  )

  const voteInPoll = useCallback(
    async (
      organizationId: number,
      pollId: number,
      choiceIds: number[],
      onSuccess: Function
    ) => {
      setLoading(true)
      setError(null)
      let results
      try {
        results = await request(organizationId, pollId, choiceIds)
        setData(results)
        if (onSuccess) {
          await onSuccess()
        }
      } catch (error) {
        setError(error)
      } finally {
        setLoading(false)
      }

      return results
    },
    [request, setLoading, setError]
  )

  return {
    loading,
    error,
    voteInPoll,
    poll: data,
  }
}

export default useVoteInPollById
