import { useCallback } from "react"
import config from "../../../config"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Organization, UpdateOrganization } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useUpdateHouse = () => {
  const { loading, setLoading, error, setError, authBearer } =
    useHandleRequest()

  const request = useCallback(
    (
      organizationId: number,
      body: UpdateOrganization
    ): Promise<Organization> => {
      return makeRequest<Organization>(
        `${config.apiUrl}/v1/organizations/${organizationId}`,
        {
          method: "PUT",
          headers: {
            authorization: `Bearer ${authBearer}`,
            "Content-Type": "application/json",
            "X-Organization-Id": `${organizationId}`,
          },
          body: JSON.stringify({ ...body }),
        }
      )
    },
    [authBearer]
  )

  const updateHouse = useCallback(
    async (
      organizationId: number,
      body: UpdateOrganization,
      onSuccess: Function
    ) => {
      setLoading(true)
      setError(null)
      let result
      try {
        result = await request(organizationId, body)
        if (onSuccess) {
          await onSuccess()
        }
      } catch (error) {
        setError(error)
      } finally {
        setError(null)
        setLoading(false)
      }

      return result
    },
    [request, setLoading, setError]
  )

  return { updateHouse, loading, error }
}

export default useUpdateHouse
