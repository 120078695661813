const assertAuthToken = (authToken: string | null): string | null => {
  if (!authToken) {
    console.warn("Missing token")
    return null
  }

  return authToken
}

export default assertAuthToken
