import React, { FunctionComponent, useEffect } from "react"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/ScreenContainer"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import {
  Badge,
  Box,
  Card,
  CardContent,
  CircularProgress,
  TablePagination,
  Typography,
} from "@mui/material"
import useGetMyNotifications from "./hooks/useGetMyNotifications"
import useMarkNotificationsAsRead from "./hooks/useMarkNotificationsAsRead"
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied"
import { scopes } from "../../scopes"

const NotificationsScreen: FunctionComponent = () => {
  const { getNotifications, loading, error, notifications, paginationData } =
    useGetMyNotifications()
  const { markNotificationsAsRead } = useMarkNotificationsAsRead()

  useEffect(() => {
    getNotifications()
    markNotificationsAsRead()
    // eslint-disable-next-line
  }, [])

  if (loading) {
    return (
      <ScreenContainer title="Notifications">
        <Box
          display="flex"
          width="100%"
          sx={{
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            alignSelf: "center",
            mb: "24px",
          }}
        >
          <CircularProgress />
        </Box>
      </ScreenContainer>
    )
  }

  return (
    <ScreenContainer
      title="Notifications"
      requiresScope={scopes.user.notifications.read}
    >
      {error ? <ErrorComponent error={error} /> : null}
      {loading ? <LoadingSpinner /> : null}
      {paginationData.count === 0 ? (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "80vh",
            }}
          >
            <SentimentDissatisfiedIcon fontSize="large" />
            <Typography variant="h5">
              <b>Nothing to see here...</b>
            </Typography>
            <Typography variant="body1">
              When you get notifications, they'll appear here. <br />
              In the meantime, enjoy the peace and quiet.
            </Typography>
          </Box>
        </>
      ) : (
        <>
          <TablePagination
            component="div"
            count={paginationData?.count || 0}
            page={paginationData?.page}
            onPageChange={paginationData?.onPageChange}
            rowsPerPage={paginationData?.rowsPerPage}
            onRowsPerPageChange={paginationData?.onRowsPerPageChange}
          />
          {notifications.map((notification) => {
            return (
              <Card
                sx={{
                  marginBottom: 2,
                  boxShadow: "none",
                  border: 1,
                  borderColor: "gray",
                }}
                key={notification.id}
              >
                <CardContent sx={{ p: 2, "&:last-child": { pb: 2 } }}>
                  {!notification.read && (
                    <Badge color="warning" variant="dot" />
                  )}
                  {notification.title && (
                    <Typography variant="h6">
                      <b>{notification.title}</b>
                    </Typography>
                  )}
                  {notification.subtitle && (
                    <Typography variant="subtitle1">
                      {notification.subtitle}
                    </Typography>
                  )}
                  {notification.message && (
                    <Typography variant="body1">
                      {notification.message}
                    </Typography>
                  )}
                </CardContent>
              </Card>
            )
          })}
          <TablePagination
            component="div"
            count={paginationData?.count || 0}
            page={paginationData?.page}
            onPageChange={paginationData?.onPageChange}
            rowsPerPage={paginationData?.rowsPerPage}
            onRowsPerPageChange={paginationData?.onRowsPerPageChange}
          />
        </>
      )}
    </ScreenContainer>
  )
}

export default NotificationsScreen
