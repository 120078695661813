import { useCallback, useState } from "react"
import config from "../../../config"
import useHandleRequest from "../../../hooks/useHandleRequest"
import makeRequest from "../../../utils/make-request"
import { Invite } from "../../../types"

const useCreateInvite = () => {
  const { loading, setLoading, error, setError, authBearer } =
    useHandleRequest()
  const [data, setData] = useState<Invite>()

  const request = useCallback(
    (
      houseId: number,
      unlimited: boolean,
      max: number | null
    ): Promise<Invite> => {
      return makeRequest<Invite>(
        `${config.apiUrl}/v1/organizations/${houseId}/members/invites`,
        {
          method: "POST",
          headers: {
            authorization: `Bearer ${authBearer}`,
            "Content-Type": "application/json",
            "X-Organization-Id": `${houseId}`,
          },
          body: unlimited
            ? JSON.stringify({ unlimited_uses: unlimited })
            : JSON.stringify({ unlimited_uses: unlimited, max_usages: max }),
        }
      )
    },
    [authBearer]
  )

  const createInvite = useCallback(
    async (
      houseId: number,
      unlimited: boolean,
      max: number | null,
      onSuccess: Function
    ) => {
      setLoading(true)
      setError(null)
      let results
      try {
        results = await request(houseId, unlimited, max)
        setData(results)
        if (onSuccess) {
          await onSuccess()
        }
      } catch (error) {
        setError(error)
      } finally {
        setLoading(false)
      }

      return results
    },
    [request, setLoading, setError]
  )

  return {
    loading,
    error,
    createInvite,
    invite: data,
  }
}

export default useCreateInvite
