import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/pro-regular-svg-icons"
import Button from "./Button"
import { lightTheme as defaultTheme } from "../../constants/theme"

interface AddButtonProps {
  className?: string;
  text?: string;
}

const AddButton: React.FC<AddButtonProps> = ({ className, text }) => {
  const [buttonText, setButtonText] = useState<string | undefined>(undefined)

  useEffect(() => {
    setButtonText(text)
  }, [text])

  return (
    <Button className={className}>
      {buttonText ? buttonText : <FontAwesomeIcon icon={faPlus} />}
    </Button>
  )
} 

const StyledAddButton = styled(AddButton)<{ text?: string }>`
  height: ${({ text }) => text ? '50px' : '75px'};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ text }) => text ? '0 10px' : '0'};
  ${({ text }) => text ? 'min-width: 75px;' : 'width: 75px;'}
  border-radius: ${({ text }) => text ? '10px' : '50%'};
  font-size: ${({ text }) => text ? '20px' : '24px'};
  cursor: pointer;
  color: ${defaultTheme.palette.background.default};
  background-color: ${defaultTheme.palette.primary.main};
  &:hover {
    opacity: 0.8;
  }
`

export default StyledAddButton