import React from "react"
import BasicTable, {
  PaginationData,
} from "../../../components/shared/BasicTable/BasicTable"
import { AttendanceItem } from "../../../types"
import AttendanceItemRow from "./AttendanceItemRow"

interface Props {
  attendanceItems: AttendanceItem[]
  paginationData: PaginationData
}

const getRows = (attendanceItems: AttendanceItem[]) => {
  return attendanceItems.map((attendanceItem, index) => {
    return <AttendanceItemRow key={index} attendanceItem={attendanceItem} />
  })
}

const AttendanceItemTable = ({ attendanceItems, paginationData }: Props) => {
  const columns: string[] = ["Title", "Created"]

  return (
    <BasicTable
      columns={columns}
      rows={getRows(attendanceItems)}
      paginationOptions={paginationData}
    />
  )
}

export default AttendanceItemTable
