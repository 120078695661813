import React, { FunctionComponent, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import ErrorComponent from "../../components/shared/Error"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import { useAuth } from "../../services/auth-service"
import enforceError from "../../utils/enforce-error"
import {
  Container,
  Paper,
  Grid,
  Typography,
  TextField,
  Button,
  Box,
} from "@mui/material"
import "./SignupScreen.css"
import redLogo from "../../assets/redLogo.svg"
import largeImage from "../../assets/signUpImage.svg"

interface RedirectParamsType {
  pathname: string
  search?: string
}

interface SearchParamType {
  name: string
  value: string
}

const createSearchQuery = (searchParams: SearchParamType[]) => {
  return searchParams.map((param) => `${param.name}=${param.value}`).join("&")
}

const SignupScreen: FunctionComponent = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const [emailError, setEmailError] = useState("")
  const [passwordError, setPasswordError] = useState("")
  const [nameError, setNameError] = useState("")
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [repeatPassword, setRepeatPassword] = useState("")
  const auth = useAuth()
  const navigate = useNavigate()
  const location: any = useLocation()
  const redirectPath = location?.state?.redirectPath || "/"

  const navigateToLogin = () => {
    navigate("/login")
  }

  const handleSubmit = async () => {
    // Handle the submission of email and password here

    if (name === "") {
      setNameError("Name is required")
      return
    }

    if (email === "") {
      setEmailError("Email is required")
      return
    }

    if (password === "") {
      setPasswordError("Password is required")
      return
    }

    if (password !== repeatPassword) {
      setPasswordError("Passwords do not match")
      return
    }

    try {
      setLoading(true)
      await auth.handleSignup(name, email, password)
      const navigationProps: RedirectParamsType = {
        pathname: redirectPath,
      }
      const redirectSearchParams = location?.state?.searchParams
      if (redirectSearchParams?.length) {
        navigationProps.search = createSearchQuery(redirectSearchParams)
      }
      navigate(navigationProps)
    } catch (error) {
      setError(enforceError(error))
    } finally {
      setLoading(false)
    }
  }

  if (auth.isAuthenticated()) {
    const navigationProps: RedirectParamsType = {
      pathname: redirectPath,
    }
    const redirectSearchParams = location?.state?.searchParams
    if (redirectSearchParams?.length) {
      navigationProps.search = createSearchQuery(redirectSearchParams)
    }
    navigate(navigationProps)
  }

  return (
    <div className="page-container">
      <div className="signup-wrapper">
        <Container maxWidth="sm" className="signup-container">
          <Paper elevation={0} style={{ padding: 20, margin: "20px auto" }}>
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12}>
                <img
                  className="signup-logo"
                  alt="Greek Chapter Logo"
                  src={redLogo}
                />
                <Typography variant="h4" align="center" fontWeight="bold">
                  Create an Account
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {error ? <ErrorComponent error={error} /> : null}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  className="signup-input"
                  label="Name"
                  fullWidth
                  required
                  variant="outlined"
                  value={name}
                  disabled={loading}
                  onChange={(e) => {
                    setName(e.target.value)
                    setNameError("")
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit()
                    }
                  }}
                />
                {nameError ? (
                  <Box sx={{ color: "red", fontSize: 12 }}>{nameError}</Box>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  className="signup-input"
                  label="Email"
                  fullWidth
                  required
                  variant="outlined"
                  type="email"
                  value={email}
                  disabled={loading}
                  onChange={(e) => {
                    setEmail(e.target.value)
                    setEmailError("")
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit()
                    }
                  }}
                />
                {emailError ? (
                  <Box sx={{ color: "red", fontSize: 12 }}>{emailError}</Box>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  className="signup-input"
                  label="Password"
                  fullWidth
                  required
                  type="password"
                  variant="outlined"
                  value={password}
                  disabled={loading}
                  onChange={(e) => {
                    setPassword(e.target.value)
                    if (repeatPassword !== e.target.value) {
                      setPasswordError("Passwords do not match")
                    } else {
                      setPasswordError("")
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit()
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  className="signup-input"
                  label="Confirm Password"
                  fullWidth
                  required
                  type="password"
                  variant="outlined"
                  value={repeatPassword}
                  disabled={loading}
                  onChange={(e) => {
                    setRepeatPassword(e.target.value)
                    if (password !== e.target.value) {
                      setPasswordError("Passwords do not match")
                    } else {
                      setPasswordError("")
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit()
                    }
                  }}
                />
                {passwordError ? (
                  <Box sx={{ color: "red", fontSize: 12 }}>{passwordError}</Box>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  className="signup-button"
                  fullWidth
                  onClick={handleSubmit}
                  sx={{ textTransform: "none", fontWeight: "bold" }}
                  disabled={loading}
                >
                  Create Account
                </Button>
              </Grid>
            </Grid>
            <Typography
              variant="body1"
              align="center"
              color="#505050"
              sx={{ mt: 2 }}
            >
              Already have an account?{" "}
              <u
                style={{
                  cursor: "pointer",
                  textDecoration: "none",
                  fontWeight: "bold",
                }}
                onClick={navigateToLogin}
              >
                Login
              </u>
            </Typography>
          </Paper>
        </Container>
      </div>
      <img className="signup-image" alt="Signup Image" src={largeImage} />
    </div>
  )
}

export default SignupScreen
