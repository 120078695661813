import { useCallback, useState } from "react"
import config from "../../../config"
import useHandleRequest from "../../../hooks/useHandleRequest"
import makeRequest from "../../../utils/make-request"
import { GuestList } from "../../../types"

const useDeleteGuestListGuest = () => {
  const { loading, setLoading, error, setError, authBearer } =
    useHandleRequest()
  const [data, setData] = useState<GuestList>()

  const request = useCallback(
    (
      organizationId: number,
      guestListId: number,
      guestId: number
    ): Promise<GuestList> => {
      return makeRequest<GuestList>(
        `${config.apiUrl}/v1/organizations/${organizationId}/guest-lists/${guestListId}/guests/${guestId}`,
        {
          method: "DELETE",
          headers: {
            authorization: `Bearer ${authBearer}`,
            "Content-Type": "application/json",
            "X-Organization-Id": `${organizationId}`,
          },
        }
      )
    },
    [authBearer]
  )

  const deleteGuestListGuest = useCallback(
    async (
      organizationId: number,
      guestListId: number,
      guestId: number,
      onSuccess: Function
    ) => {
      setLoading(true)
      setError(null)
      let results
      try {
        results = await request(organizationId, guestListId, guestId)
        setData(results)
        if (onSuccess) {
          await onSuccess()
        }
      } catch (error) {
        setError(error)
      } finally {
        setLoading(false)
      }

      return results
    },
    [request, setLoading, setError]
  )

  return {
    loading,
    error,
    deleteGuestListGuest,
    guestList: data,
  }
}

export default useDeleteGuestListGuest
