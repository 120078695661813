import { createTheme } from "@mui/material/styles"

// TODO: reduce this list by converting things to use mui theme.palette

const lightTheme = createTheme({
  typography: {
    button: {
      textTransform: "none",
    },
  },
  palette: {
    primary: {
      main: "#FF4444",
    },
    secondary: {
      main: "#c5c5c5",
    },
    background: {
      default: "#ffffff",
    },
    text: {
      primary: "#505050",
    },
    error: {
      main: "#ff604f",
    },
    warning: {
      main: "#ff9800",
    },
  },
})

export {lightTheme}
