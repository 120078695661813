import {
  Backdrop,
  Box,
  Breakpoint,
  CircularProgress,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material"
import { styled } from "@mui/material/styles"
import React, { FunctionComponent, ReactNode } from "react"
import ErrorComponent from "./Error"
import { Subhead, Title3 } from "./Typography"
export interface DialogProps {
  title: string
  isOpen: boolean
  handleClose: () => any
  error?: any
  buttonContent?: ReactNode
  content: ReactNode
  maxWidth?: Breakpoint
  loading?: boolean
}

const Dialog: FunctionComponent<DialogProps> = ({
  title,
  isOpen,
  handleClose,
  error,
  content,
  buttonContent,
  maxWidth = "sm",
  loading,
}) => {
  return (
    <>
      {loading && isOpen ? (
        <Backdrop open={loading} style={{ zIndex: 1600 }}>
          <CircularProgress color="primary" />
        </Backdrop>
      ) : null}
      <MuiDialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth={maxWidth}
      >
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          {error ? <ErrorComponent error={error} /> : null}
          {content}
        </DialogContent>
        {buttonContent && <DialogActions>{buttonContent}</DialogActions>}
      </MuiDialog>
    </>
  )
}

export default Dialog

export const Row = styled(Box)``
Row.defaultProps = {
  sx: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  width: "100%",
  display: "flex",
  flexWrap: "wrap",
}

export const StyledText: any = styled(Subhead)`
  margin-left: 5px;
  margin-right: 5px;
  font-weight: 400;
`

export const StyledTitle = styled(Title3)`
  color: ${({ theme }) => theme.palette.primary.main};
`
