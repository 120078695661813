import React, { FunctionComponent } from "react"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import { GuestList } from "../../../types"
import { useNavigate } from "react-router-dom"
import { Box } from "@mui/material"
import styles from './GuestListRow.module.css'
import { lightTheme } from "../../../constants/theme"
import useBreakpoint from "../../../hooks/useBreakpoint"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretRight } from "@fortawesome/pro-regular-svg-icons"

type Props = {
  guestList: GuestList
}

const StudyHoursRow: FunctionComponent<Props> = ({ guestList }) => {
  const navigate = useNavigate()
  const breakpoint = useBreakpoint()

  if(breakpoint === "xxs" || breakpoint === "xs") {
    return (
      <BasicTableRow key={guestList.id} onClick={() => navigate(`/guest-lists/${guestList.id}`)} sx={{ borderRadius: "10px", '&:hover' : {backgroundColor: lightTheme.palette.secondary.main, cursor: "pointer"}}}>
        <BasicTableCell
          component="th"
          scope="row"
          style={{ cursor: "pointer", width: "100%" }}
        >
          <Box className={styles.itemContainer}>
            <Box>
            <Box className={styles.listTitle}>
              {guestList.name}
            </Box>
            <b>Max Guests: </b>{guestList.max_guests}
            </Box>
            <FontAwesomeIcon icon={faCaretRight} className={styles.icon}/>
          </Box>
        </BasicTableCell>
      </BasicTableRow>
    )
  }
  return (
    <BasicTableRow key={guestList.id} onClick={() => navigate(`/guest-lists/${guestList.id}`)} sx={{ borderRadius: "10px", '&:hover' : {backgroundColor: lightTheme.palette.secondary.main, cursor: "pointer"}}}>
      <BasicTableCell
        component="th"
        scope="row"
        style={{ cursor: "pointer" }}
        className={styles.cell}
      >
        <Box className={styles.cellContainer}>
          <Box className={styles.listTitle}>
            {guestList.name}
          </Box>
          <Box>
          <b>Max Guests: </b>{guestList.max_guests}
          </Box>
        </Box>
      </BasicTableCell>
      <BasicTableCell component="th" scope="row">
        <Box className={`${guestList.editing_enabled ? styles.active : styles.inactive} ${styles.display}`}>
          {guestList.editing_enabled ? "Editing Enabled" : "Editing Disabled"}
        </Box>
        </BasicTableCell>
        <BasicTableCell component="th" scope="row">
        <Box className={`${guestList.is_visible ? styles.visible : styles.inactive} ${styles.display}`}>
          {guestList.is_visible ? "Visible to Members" : "Hidden from Members"}
        </Box>
      </BasicTableCell>
    </BasicTableRow>
  )
}

export default StudyHoursRow
