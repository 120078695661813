import { Button, FormControlLabel, Switch, TextField } from "@mui/material"
import { useFormik } from "formik"
import React, {
  FunctionComponent,
  useState,
  useEffect,
  useCallback,
} from "react"
import toast from "react-hot-toast"
import * as Yup from "yup"
import Dialog from "../../../components/shared/Dialog"
import ErrorComponent from "../../../components/shared/Error"
import useCreateStudyLocation from "../hooks/useCreateStudyLocation"
import { StudyLocation } from "../../../types"
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
  Circle,
} from "@react-google-maps/api"
import config from "../../../config"
import useUpdateStudyLocation from "../hooks/useUpdateStudyLocation"
import Autocomplete from "react-google-autocomplete"
import useDeleteStudyLocation from "../hooks/useDeleteStudyLocation"
import { lightTheme } from "../../../constants/theme"

interface Props {
  isOpen: boolean
  close: () => void
  studyLocation?: StudyLocation | null
  onSuccess?: () => void
}

const StudyLocationDialog: FunctionComponent<Props> = ({
  isOpen,
  close,
  studyLocation,
  onSuccess,
}) => {
  const { createStudyLocation, error, loading } = useCreateStudyLocation()
  const {
    updateStudyLocation,
    error: updateError,
    loading: updateLoading,
  } = useUpdateStudyLocation()

  const { deleteStudyLocation } = useDeleteStudyLocation()

  const getLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      setViewport({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
        zoom: 8,
      })
      setMarker({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      })
    })
  }

  React.useEffect(() => {
    if (!studyLocation) {
      getLocation()
    } else {
      setViewport({
        lat: studyLocation.latitude,
        lng: studyLocation.longitude,
        zoom: 8,
      })
      setMarker({
        lat: studyLocation.latitude,
        lng: studyLocation.longitude,
      })
    }
  }, [isOpen, studyLocation])

  const [marker, setMarker] = React.useState({
    lat: 37.7577,
    lng: -122.4376,
  })

  const [viewport, setViewport] = React.useState({
    lat: marker.lat,
    lng: marker.lng,
    zoom: 50,
  })

  const onMarkerDragEnd = (event: any) => {
    setMarker({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    })
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    description: Yup.string().nullable().optional(),
    radius: Yup.number().required().min(0),
  })

  const handleClose = () => {
    close()
    getLocation()
  }

  const formik = useFormik({
    initialValues: {
      name: "",
      description: null,
      radius: 10,
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      const organizationId = localStorage.getItem("activeOrganizationId")
      if (organizationId) {
        if (studyLocation) {
          const body = {
            name: values.name,
            description: values.description,
            coordinates: `(${marker.lat}, ${marker.lng})`,
            latitude: marker.lat,
            longitude: marker.lng,
            radius: values.radius,
          }

            const updatedStudyLocation = await updateStudyLocation(
              Number(organizationId),
              Number(studyLocation.id),
              body,
              handleUpdateSuccess
            )
            if (updatedStudyLocation) {
              formik.resetForm()
              onSuccess && onSuccess()
            }
        
        } else {
          const body = {
            name: values.name,
            description: values.description,
            coordinates: `(${marker.lat}, ${marker.lng})`,
            latitude: marker.lat,
            longitude: marker.lng,
            radius: values.radius,
          }
          getLocation()
          const createdLocation = await createStudyLocation(
            Number(organizationId),
            body,
            handleCreateSuccess
          )
          if (createdLocation) {
            formik.resetForm()
            onSuccess && onSuccess()
          }
        }
      }
    },
  })

  const handleCreateSuccess = () => {
    toast.success(`Successfully created study location`)
    close()
  }

  const handleUpdateSuccess = () => {
    toast.success(`Successfully updated study location`)
    close()
  }

  const handleDeleteSuccess = () => {
    toast.success(`Successfully deleted study location`)
    close()
  }

  React.useEffect(() => {
    if (studyLocation) {
      formik.setFieldValue("name", studyLocation.name)
      formik.setFieldValue("description", studyLocation.description)
      formik.setFieldValue("radius", studyLocation.radius)
    }
  }, [studyLocation])

  const containerStyle = {
    width: "100%",
    height: "500px",
  }

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: config.mapsAPIKey,
  })

  const handleDeleteClick = async (studyLocation: StudyLocation) => {
    const organizationId = localStorage.getItem("activeOrganizationId")
    if (organizationId) {
      await deleteStudyLocation(Number(organizationId), Number(studyLocation.id), handleDeleteSuccess)
      onSuccess && onSuccess()
      close()
    }
  }


  return isLoaded ? (
    <Dialog
      title={studyLocation ? "Update Study Location" : "Create Study Location"}
      isOpen={isOpen}
      handleClose={handleClose}
      loading={loading}
      content={
        <>
          {error && <ErrorComponent error={error} />}
          {updateError && <ErrorComponent error={updateError} />}
          <TextField
            margin="dense"
            id="name"
            name="name"
            label={"Name"}
            fullWidth
            required
            variant="outlined"
            onChange={formik.handleChange}
            error={!!formik.errors.name}
            value={formik.values.name}
          />
          <TextField
            margin="dense"
            id="description"
            name="description"
            label="Description (optional)"
            fullWidth
            variant="outlined"
            multiline
            onChange={formik.handleChange}
            error={!!formik.errors.description}
            value={formik.values.description}
          />
          <TextField
            label="Radius (meters)"
            id="radius"
            type="number"
            sx={{ mt: 2, mb: 2 }}
            error={!!formik.errors.radius}
            value={formik.values.radius}
            onChange={formik.handleChange}
          />
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={viewport}
            zoom={17}
          >
            <Marker position={marker} draggable onDragEnd={onMarkerDragEnd} />
            <Circle
              center={marker}
              radius={formik.values.radius}
              options={{
                strokeColor: "#FF4444",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#FF4444",
                fillOpacity: 0.35,
              }}
            />
          </GoogleMap>
        </>
      }
      buttonContent={
        <>
          <Button onClick={handleClose} color="error" variant="outlined">
            Close
          </Button>
          <Button
            onClick={formik.submitForm}
            color="primary"
            variant="outlined"
            disabled={loading || updateLoading}
          >
            {studyLocation ? "Update" : "Create"}
          </Button>
          {studyLocation && (
            <Button
              onClick={() => handleDeleteClick(studyLocation)}
              sx={{ 
                backgroundColor: lightTheme.palette.primary.main, 
                color: lightTheme.palette.background.default,
                transition: "all 0.3s",
                "&:hover": {
                  backgroundColor: lightTheme.palette.primary.main,
                  color: lightTheme.palette.background.default,
                  opacity: 0.8
                }
              }}
            >
              Delete
            </Button>
          )}
        </>
      }
    />
  ) : (
    <></>
  )
}

export default StudyLocationDialog
