import { Box, Button, CircularProgress, Typography } from "@mui/material"
import React, { FunctionComponent, useEffect, useState } from "react"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/ScreenContainer"
import useGetHouseById from "../Organizations/hooks/useGetHouseById"
import { scopes } from "../../scopes"
import useGetStudyLocations from "./hooks/useGetStudyLocations"
import StudyLocationDialog from "./components/StudyLocationDialog"
import styles from "./StudyLocationsScreen.module.css"
import AddButton from "../../components/shared/AddButton"
import StudyLocationsMap from "./components/StudyLocationsMap"

const StudyLocationsScreen: FunctionComponent = () => {

  const [createStudyLocationOpen, setCreateStudyLocationOpen] = useState(false)

  const {
    getHouseById,
    house: organization,
    loading: organizationLoading,
    error: organizationError,
  } = useGetHouseById()
  const {
    getStudyLocations,
    paginationData,
    studyLocations,
    loading: studyLocationsLoading,
    error: studyLocationsError,
  } = useGetStudyLocations()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const organizationId = localStorage.getItem("activeOrganizationId")

        if (organizationId) {
          await getHouseById(Number(organizationId))
          await getStudyLocations(Number(organizationId))

          if (organization) {
            localStorage.setItem(
              "activeOrganization",
              JSON.stringify(organization)
            )
          }
        }
      } catch (err) {
        console.error(`Error loading organization: ${organizationError}`)
      }
    }

    fetchData()
  }, [])

  if (organizationLoading) {
    return (
      <ScreenContainer>
        <Box
          display="flex"
          width="100%"
          sx={{
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            alignSelf: "center",
            mb: "24px",
          }}
        >
          <CircularProgress />
        </Box>
      </ScreenContainer>
    )
  }

  const onSuccess = async () => {
    const organizationId = localStorage.getItem("activeOrganizationId")
    if (organizationId) {
      await getStudyLocations(Number(organizationId))
    }
  }

  return (
    <ScreenContainer
      requiresScope={scopes.organization.studyHours.locations.create}
    >
      <Box
        display="flex"
        width="100%"
        sx={{
          flexDirection: "column",
          mb: "24px",
        }}
        className={styles.studyLocationsScreen}
      >
        {studyLocationsError && <ErrorComponent error={studyLocationsError} />}
        {studyLocationsLoading && <CircularProgress />}
        <Typography variant="h3" className={styles.title}>
          Study Locations
        </Typography>
        <Typography variant="h6" className={styles.subtitle}>
          Click or tap a study location to view or edit details
        </Typography>
        <StudyLocationsMap studyLocations={studyLocations} onSuccess={onSuccess} />
      </Box>
      <StudyLocationDialog
        isOpen={createStudyLocationOpen}
        close={() => setCreateStudyLocationOpen(false)}
        onSuccess={onSuccess}
      />
      <Box className={styles.createLocationButtonContainer} onClick={() => setCreateStudyLocationOpen(true)}>
      <AddButton className="className" />
      </Box>
    </ScreenContainer>
  )
}

export default StudyLocationsScreen
