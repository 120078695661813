import React, { FunctionComponent, useEffect, useState } from "react"
import {
  Box,
  Checkbox,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/ScreenContainer"
import useGetHouseById from "../Organizations/hooks/useGetHouseById"
import { useParams } from "react-router-dom"
import AddIcon from "@mui/icons-material/Add"
import SettingsIcon from "@mui/icons-material/Settings"
import toast from "react-hot-toast"
import { GuestListMember } from "../../types"
import { useAuth } from "../../services/auth-service"
import DeleteIcon from "@mui/icons-material/Delete"
import { useMenu } from "../../components/shared/MenuContext"
import styles from "../GuestLists/GuestListDetailsScreen.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons"
import { useNavigate } from "react-router-dom"
import { Settings } from "@mui/icons-material"
import useGetAttendanceItemById from "./hooks/useGetAttendanceItemById"
import { Avatar } from "@material-ui/core"
import useUpdateMemberAttendance from "./hooks/useUpdateMemberAttendance"
import AttendanceMemberRow from "./components/AttendanceMemberRow"

type Params = {
  attendanceItemId?: string
}

const AttendanceItemDetailsScreen: FunctionComponent = () => {
  const params: Params = useParams()
  const attendanceItemId = Number(params.attendanceItemId)
  const [searchTerm, setSearchTerm] = useState("")
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const { getUser } = useAuth()
  const currentUserId = getUser()?.id

  const navigate = useNavigate()

  const {
    getHouseById,
    house,
    loading: organizationLoading,
    error: organizationError,
  } = useGetHouseById()
  const {
    getAttendanceItem,
    attendanceItem,
    loading: attendanceItemLoading,
    error: attendanceItemError,
  } = useGetAttendanceItemById()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const organizationId = localStorage.getItem("activeOrganizationId")

        if (organizationId) {
          await getHouseById(Number(organizationId))
          await getAttendanceItem(Number(organizationId), attendanceItemId)

          if (house) {
            localStorage.setItem("activeOrganization", JSON.stringify(house))
          }
        }
      } catch (err) {
        console.error(`Error loading organization: ${organizationError}`)
      }
    }

    fetchData()
  }, [])

  const handleOpenDialog = () => {
    setIsDialogOpen(true)
  }

  const handleCloseDialog = () => {
    setIsDialogOpen(false)
  }

  if (organizationLoading || attendanceItemLoading) {
    return (
      <ScreenContainer>
        <Box
          display="flex"
          width="100%"
          sx={{
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            alignSelf: "center",
            mb: "24px",
          }}
        >
          <CircularProgress />
        </Box>
      </ScreenContainer>
    )
  }

  if (!attendanceItem) {
    return (
      <ScreenContainer title={"Attendance item not found"}></ScreenContainer>
    )
  }

  return (
    <ScreenContainer
      icon={<SettingsIcon />}
      iconAction={() => setIsDialogOpen(true)}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            marginRight: 2,
            cursor: "pointer",
          }}
          onClick={() => navigate("/attendance")}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </Box>
        <Box className={styles.name}>
          {attendanceItem?.title}
          {/* <IconButton
            sx={{
              marginLeft: 2,
            }}
            onClick={() => setIsDialogOpen(true)}
          >
            <SettingsIcon />
          </IconButton> */}
        </Box>
      </Box>
      <Box>
        <Table component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Present</TableCell>
                <TableCell>Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {attendanceItem.members.map((member) => (
                <AttendanceMemberRow
                  key={member.id}
                  member={member}
                  attendanceItem={attendanceItem}
                />
              ))}
            </TableBody>
          </Table>
        </Table>
      </Box>
    </ScreenContainer>
  )
}

export default AttendanceItemDetailsScreen
