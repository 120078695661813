import { useCallback } from "react"
import config from "../../../config"
import useHandleRequest from "../../../hooks/useHandleRequest"
import makeRequest from "../../../utils/make-request"

const useDeleteHouse = () => {
  const { loading, setLoading, error, setError, authBearer } =
    useHandleRequest()

  const request = useCallback(
    (houseId: number): Promise<void> => {
      return makeRequest<void>(`${config.apiUrl}/v1/organizations/${houseId}`, {
        method: "DELETE",
        headers: {
          authorization: `Bearer ${authBearer}`,
          "Content-Type": "application/json",
          "X-Organization-Id": `${houseId}`,
        },
      })
    },
    [authBearer]
  )

  const deleteHouse = useCallback(
    async (houseId: number, onSuccess: Function) => {
      setLoading(true)
      setError(null)
      let result
      try {
        result = await request(houseId)
        if (onSuccess) {
          await onSuccess()
        }
      } catch (error) {
        setError(error)
      } finally {
        setError(null)
        setLoading(false)
      }

      return result
    },
    [request, setLoading, setError]
  )

  return { deleteHouse, loading, error }
}

export default useDeleteHouse
