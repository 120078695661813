import React from 'react'
import styles from './ActiveButton.module.css'
import { lightTheme } from '../../../constants/theme'

const ActiveButton = ({ isActive }: { isActive: boolean }) => {
  return (
    <div className={`${styles.activeButton} ${isActive ? styles.active : styles.inactive}`}>
        <div>
        {isActive ? 'Active' : 'Inactive'}
        </div>
    </div>
  )
}

export default ActiveButton