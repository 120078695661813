import { Button, TextField } from "@mui/material"
import { useFormik } from "formik"
import React, { FunctionComponent } from "react"
import toast from "react-hot-toast"
import * as Yup from "yup"
import Dialog from "../../../components/shared/Dialog"
import ErrorComponent from "../../../components/shared/Error"
import useCreatePointItem from "../hooks/useCreatePointItem"

interface Props {
  isOpen: boolean
  close: () => void
  onSuccess?: () => void
}

const CreatePointItemDialog: FunctionComponent<Props> = ({
  isOpen,
  close,
  onSuccess,
}) => {
  const { createPointItem, error, loading } = useCreatePointItem()

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    description: Yup.string().nullable().optional(),
  })

  const handleClose = () => {
    close()
  }

  const formik = useFormik({
    initialValues: {
      title: "",
      description: null,
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      const organizationId = localStorage.getItem("activeOrganizationId")
      console.log(organizationId)
      if (organizationId) {
        const body = {
          title: values.title,
          description: values?.description,
        }
        const itemCreated = await createPointItem(
          Number(organizationId),
          body,
          handleCreateSuccess
        )
        if (itemCreated) {
          formik.resetForm()
          onSuccess && onSuccess()
        }
      }
    },
  })

  const handleCreateSuccess = () => {
    toast.success(
      `Successfully created ${formik.values.title} point item!`
    )
    close()
  }

  return (
    <Dialog
      title={`Creat Point Item`}
      isOpen={isOpen}
      handleClose={handleClose}
      loading={loading}
      content={
        <>
          {error && <ErrorComponent error={error} />}
          <TextField
            margin="dense"
            id="title"
            name="title"
            label={"Title"}
            fullWidth
            required
            variant="outlined"
            type="text"
            onChange={formik.handleChange}
            error={!!formik.errors.title}
            value={formik.values.title}
          />
          <TextField
            margin="dense"
            id="description"
            name="description"
            label="Description"
            placeholder="Description of point item"
            fullWidth
            variant="outlined"
            multiline
            onChange={formik.handleChange}
            error={!!formik.errors.description}
            value={formik.values.description}
          />
        </>
      }
      buttonContent={
        <>
          <Button onClick={handleClose} color="error" variant="outlined">
            Close
          </Button>
          <Button
            onClick={formik.submitForm}
            color="primary"
            variant="outlined"
            disabled={loading}
          >
            Create Item
          </Button>
        </>
      }
    />
  )
}

export default CreatePointItemDialog
