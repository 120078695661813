import React from "react"
import BasicTable, {
  PaginationData,
} from "../../../components/shared/BasicTable/BasicTable"
import { OrganizationMember, OrganizationMemberRole } from "../../../types"
import OrganizationMemberRow from "./OrganizationMemberRow"

interface Props {
  members: OrganizationMember[]
  paginationData: PaginationData
  canEdit: boolean
  canDelete: boolean
  roles: OrganizationMemberRole[]
}

const getRows = (
  members: OrganizationMember[],
  canEdit: boolean,
  canDelete: boolean,
  roles: OrganizationMemberRole[]
) => {
  return members.map((member, index) => {
    return (
      <OrganizationMemberRow
        key={index}
        member={member}
        canEdit={canEdit}
        canDelete={canDelete}
        roles={roles}
      />
    )
  })
}

const OrganizationMembersTable = ({
  members,
  paginationData,
  canEdit,
  canDelete,
  roles,
}: Props) => {
  const columns: string[] =
    canEdit || canDelete
      ? ["", "Name", "Roles", "Actions"]
      : ["", "Name", "Roles"]

  return (
    <BasicTable
      columns={columns}
      rows={getRows(members, canEdit, canDelete, roles)}
      paginationOptions={paginationData}
    />
  )
}

export default OrganizationMembersTable
