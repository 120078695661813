import React, { FunctionComponent } from "react"
import { Button, Typography } from "@mui/material"
import toast from "react-hot-toast"
import Dialog from "../../../components/shared/Dialog"
import ErrorComponent from "../../../components/shared/Error"
import { useNavigate } from "react-router-dom"
import useDeleteHouse from "../hooks/useDeleteHouse"
import { Organization } from "../../../types"

interface Props {
  isOpen: boolean
  close: () => void
  organization: Organization
}

const DeleteOrganizationDialog: FunctionComponent<Props> = ({
  isOpen,
  close,
  organization,
}) => {
  const navigate = useNavigate()
  const { deleteHouse, loading, error } = useDeleteHouse()

  const handleDelete = async () => {
    await deleteHouse(organization.id, handleDeleteSuccess)
  }

  const handleClose = () => {
    close()
  }

  const handleDeleteSuccess = () => {
    toast.success(`Successfully deleted ${organization.name}`)
    close()
    localStorage.removeItem("activeOrganization")
    localStorage.removeItem("activeOrganizationId")
    navigate("/account/organizations")
  }

  return (
    <Dialog
      title={`Delete ${organization.name}`}
      isOpen={isOpen}
      handleClose={handleClose}
      loading={loading}
      content={
        <>
          {error && <ErrorComponent error={error} />}
          <Typography>
            This is a very <b>dangerous</b> action.
          </Typography>
          <br />
          <Typography>
            <b>
              All data will be deleted and this organization will be removed
              from all organization members.
            </b>
          </Typography>
          <br />
          <Typography>
            If this is not your intention, you may also promote another member
            as an admin of the organization to take ownership. Please note that
            the button below proceeds with this action. If this is what you'd
            like to do, please continue forward with the deletion. Again, you
            are deleting <b>{organization.name}</b>.
          </Typography>
        </>
      }
      buttonContent={
        <>
          <Button onClick={handleClose} variant="outlined">
            Close
          </Button>
          <Button
            onClick={handleDelete}
            color="error"
            variant="contained"
            disabled={loading}
          >
            Delete Organization
          </Button>
        </>
      }
    />
  )
}

export default DeleteOrganizationDialog
