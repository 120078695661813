import React, { FunctionComponent, useState } from "react"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import { OrganizationMember, OrganizationMemberRole } from "../../../types"
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material"
import { useAuth } from "../../../services/auth-service"
import toast from "react-hot-toast"
import useUpdateOrganizationMember from "../hooks/useUpdateOrganizationMember"
import useDeleteOrganizationMember from "../hooks/useDeleteOrganizationMember"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit, faTrash } from "@fortawesome/pro-regular-svg-icons"
import styles from "./OrganizationMemberRow.module.css"
import { lightTheme } from "../../../constants/theme"

type Props = {
  member: OrganizationMember
  canEdit: boolean
  canDelete: boolean
  roles: OrganizationMemberRole[]
}

const OrganizationMemberRow: FunctionComponent<Props> = ({
  member,
  canEdit,
  canDelete,
  roles,
}) => {
  const [organizationMember, setOrganizationMember] =
    useState<OrganizationMember>(member)
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false)
  const [editPermissionsOpen, setEditPermissionsOpen] = useState<boolean>(false)
  const [isDeleted, setIsDeleted] = useState<boolean>(false)

  // State for checked roles
  const [checkedRoles, setCheckedRoles] = React.useState<string[]>(
    organizationMember.roles
  )

  const [position, setPosition] = useState<string | null>(
    organizationMember.position
  )
  const [gpa, setGpa] = useState<number | null>(organizationMember.gpa)
  const [year, setYear] = useState<string | null>(organizationMember.year)

  // Handle checkbox change
  const handleRoleChange = (roleId: string) => {
    setCheckedRoles((prevSelectedRoles) =>
      prevSelectedRoles.includes(roleId)
        ? prevSelectedRoles.filter((role) => role !== roleId)
        : [...prevSelectedRoles, roleId]
    )
  }

  const { getUser } = useAuth()
  const {
    updateOrganizationMember,
    loading,
    error: updateError,
  } = useUpdateOrganizationMember()
  const {
    deleteOrganizationMember,
    loading: deleteLoading,
    error: deleteError,
  } = useDeleteOrganizationMember()

  const showActionColumn = canEdit || canDelete

  const updateRoleOnMember = async () => {
    const organizationId = localStorage.getItem("activeOrganizationId")
    // try {
    const updatedMember = await updateOrganizationMember(
      Number(organizationId),
      organizationMember.id,
      { roles: checkedRoles, position, gpa, year },
      updateRoleSuccess
    )
    setOrganizationMember(updatedMember || member)
    // } catch (error) {
    //   toast.error(updateError?.message || "Failed to update role")
    // }
  }

  const updateRoleSuccess = () => {
    toast.success("Successfully updated role")
    setEditPermissionsOpen(false)
  }

  const openConfirmDialog = () => {
    setConfirmDialogOpen(true)
  }

  const closeConfirmDialog = () => {
    setConfirmDialogOpen(false)
  }

  const handleDelete = async () => {
    closeConfirmDialog()
    await deleteOrganizationMember(
      Number(localStorage.getItem("activeOrganizationId")),
      organizationMember.id,
      deleteSuccess
    )
  }

  const deleteSuccess = () => {
    toast.success("Successfully deleted house member")
    setIsDeleted(true)
  }

  if (updateError) {
    toast.error(updateError.message)
  }

  if (deleteError) {
    toast.error(deleteError.message)
  }

  const editingSelf = getUser()?.id === Number(organizationMember.user.id)

  return !isDeleted ? (
    <>
      <BasicTableRow key={organizationMember.id}>
        <BasicTableCell component="th" scope="row">
          <Avatar
            alt={organizationMember.user.name}
            src={`${organizationMember.user.profile_photo}`}
          />
        </BasicTableCell>
        <BasicTableCell component="th" scope="row">
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <span>{organizationMember.user.name}</span>
            {organizationMember.position && (
              <span style={{ color: "gray" }}>
                {organizationMember.position}
              </span>
            )}
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              {organizationMember.gpa && (
                <span style={{ color: "gray", marginRight: 5 }}>
                  {organizationMember.gpa} GPA
                </span>
              )}
              {organizationMember.year && (
                <span style={{ color: "gray", marginRight: 5 }}>
                  {organizationMember.year}
                </span>
              )}
            </Box>
          </Box>
        </BasicTableCell>
        <BasicTableCell component="th" scope="row">
          {canEdit &&
            organizationMember.roles
              .sort(
                (a, b) =>
                  roles.findIndex((role) => role.id === a) -
                  roles.findIndex((role) => role.id === b)
              )
              .map((id) => {
                const role = roles.find((role) => role.id === id)
                return (
                  <Chip
                    key={role?.id}
                    label={role?.name}
                    sx={{
                      backgroundColor: lightTheme.palette.secondary.main,
                      color: lightTheme.palette.text.primary,
                      borderRadius: 2,
                      marginRight: 0.5,
                    }}
                  />
                )
              })}
        </BasicTableCell>
        {showActionColumn && (
          <BasicTableCell component="th" scope="row">
            {canEdit && (
              <Button
                sx={{ color: lightTheme.palette.text.primary }}
                disabled={loading || deleteLoading}
                onClick={() => setEditPermissionsOpen(true)}
              >
                <FontAwesomeIcon icon={faEdit} className={styles.button} />
              </Button>
            )}{" "}
            {canDelete && !editingSelf && (
              <Button
                sx={{ color: lightTheme.palette.text.primary }}
                color="error"
                disabled={loading || deleteLoading}
                onClick={openConfirmDialog}
              >
                <FontAwesomeIcon icon={faTrash} className={styles.button} />
              </Button>
            )}
          </BasicTableCell>
        )}
      </BasicTableRow>

      <Dialog open={confirmDialogOpen} onClose={closeConfirmDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to remove {organizationMember.user.name}'s
            membership from the organization?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="error" disabled={deleteLoading}>
            Confirm Deletion
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={editPermissionsOpen}
        onClose={() => setEditPermissionsOpen(false)}
      >
        <DialogTitle>Edit {organizationMember.user.name}</DialogTitle>
        <DialogContent>
          {!editingSelf && (
            <>
              <DialogContentText>Permissions</DialogContentText>
              {roles.map((role) => (
                <Box key={role.name} sx={{ marginBottom: 2 }}>
                  <Grid container alignItems="center">
                    <Grid item>
                      <Checkbox
                        checked={checkedRoles.includes(role.id)}
                        disabled={role.editable === false}
                        onChange={() => handleRoleChange(role.id)}
                        value={role.name}
                      />
                    </Grid>
                    <Grid item xs>
                      <FormControlLabel
                        control={<></>}
                        label={
                          <Typography variant="body1" fontWeight="bold">
                            {role.name}
                          </Typography>
                        }
                        labelPlacement="end"
                        sx={{ marginLeft: 0.2 }}
                      />
                      <Typography variant="body2" color="textSecondary">
                        {role.description}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              ))}
            </>
          )}
          <TextField
            margin="dense"
            id="position"
            name="position"
            label={"Position"}
            placeholder="Position in organization (president, vice president, etc.)"
            fullWidth
            value={position}
            variant="outlined"
            type="text"
            onChange={(e) =>
              setPosition(e.target.value === "" ? null : e.target.value)
            }
          />
          <TextField
            margin="dense"
            id="gpa"
            name="gpa"
            label={"GPA"}
            placeholder="GPA"
            value={gpa}
            fullWidth
            variant="outlined"
            type="number"
            onChange={(e) =>
              setGpa(e.target.value === "" ? null : Number(e.target.value))
            }
          />
          <FormControl fullWidth variant="outlined" margin="normal">
            <InputLabel id="year-select-label">Year</InputLabel>
            <Select
              labelId="year-select-label"
              value={year}
              onChange={(event) => {
                setYear(event.target.value as string)
              }}
              label="Year"
            >
              <MenuItem value="" selected={year === null}>
                <em>Select Year</em>
              </MenuItem>
              <MenuItem value="Freshman" selected={year === "Freshman"}>
                Freshman
              </MenuItem>
              <MenuItem value="Sophomore" selected={year === "Sophomore"}>
                Sophomore
              </MenuItem>
              <MenuItem value="Junior" selected={year === "Junior"}>
                Junior
              </MenuItem>
              <MenuItem value="Senior" selected={year === "Senior"}>
                Senior
              </MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditPermissionsOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={updateRoleOnMember} color="info" disabled={loading}>
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  ) : null
}

export default OrganizationMemberRow
