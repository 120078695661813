import { Box, CircularProgress, Typography } from "@mui/material"
import React, { FunctionComponent, useEffect, useState } from "react"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/ScreenContainer"
import useGetHouseById from "../Organizations/hooks/useGetHouseById"
import useGetHouseMembers from "../Organizations/hooks/useGetHouseMembers"
import HouseMembersTable from "./components/OrganizationMembersTable"
import CreateInviteDialog from "./components/CreateInviteDialog"
import { scopes } from "../../scopes"
import { useAuth } from "../../services/auth-service"
import AddButton from "../../components/shared/AddButton"
import styles from "./OrganizationMembersScreen.module.css"
import useGetOrganizationMemberRoles from "./hooks/useGetOrganizationMemberRoles"

const OrganiztionMembersScreen: FunctionComponent = () => {
  const {
    getHouseById,
    house,
    loading: organizationLoading,
    error: houseError,
  } = useGetHouseById()
  const {
    getHouseMembers,
    paginationData,
    members,
    loading: membersLoading,
    error: membersError,
  } = useGetHouseMembers()
  const {
    getOrganizationMemberRoles,
    roles,
    loading: rolesLoading,
  } = useGetOrganizationMemberRoles()
  const { hasScope } = useAuth()

  const [createInviteOpen, setCreateInviteOpen] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const organizationId = localStorage.getItem("activeOrganizationId")

        if (organizationId) {
          await getHouseById(Number(organizationId))
          await getHouseMembers(Number(organizationId))
          await getOrganizationMemberRoles()

          if (house) {
            localStorage.setItem("activeOrganization", JSON.stringify(house))
          }
        }
      } catch (err) {
        console.error(`Error loading organization: ${houseError}`)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const inviteOpenParam = urlSearchParams.get("inviteOpen")

    if (inviteOpenParam && inviteOpenParam.toLowerCase() === "true") {
      setCreateInviteOpen(true)
    }
  }, [])

  const canCreateInvite = hasScope(scopes.organization.inviteCodes.create)
  const canEditMember = hasScope(scopes.organization.member.update)
  const canDeleteMember = hasScope(scopes.organization.member.delete)

  if (organizationLoading) {
    return (
      <ScreenContainer>
        <Box
          display="flex"
          width="100%"
          sx={{
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            alignSelf: "center",
            mb: "24px",
          }}
        >
          <CircularProgress />
        </Box>
      </ScreenContainer>
    )
  }

  return (
    <ScreenContainer requiresScope={scopes.organization.members.read}>
      <Box
        display="flex"
        width="100%"
        sx={{
          flexDirection: "column",
          mb: "24px",
        }}
        className={styles.organizationMembersScreen}
      >
        {membersError && <ErrorComponent error={membersError} />}
        {(membersLoading || rolesLoading) && <CircularProgress />}
        <Typography variant="h3" className={styles.title}>
          {house ? house.name : ""} Members
        </Typography>
        <HouseMembersTable
          members={members}
          paginationData={paginationData}
          canEdit={canEditMember}
          canDelete={canDeleteMember}
          roles={roles || []}
        />
      </Box>
      <CreateInviteDialog
        isOpen={createInviteOpen}
        close={() => setCreateInviteOpen(false)}
      />
      {canCreateInvite && (
        <Box
          className={styles.createInviteButtonContainer}
          onClick={() => setCreateInviteOpen(true)}
        >
          <AddButton className="className" text="Invite Code" />
        </Box>
      )}
    </ScreenContainer>
  )
}

export default OrganiztionMembersScreen
