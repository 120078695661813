import React, { FunctionComponent, useEffect } from "react"
import ErrorComponent from "../../components/shared/Error"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import ScreenContainer from "../../components/shared/ScreenContainer"
import useGetDeadletterEvents from "./hooks/useGetDeadletterEvents"
import DeadletterEventsTable from "./components/DeadletterEventsTable"

const DeadletterEventsScreen: FunctionComponent = () => {
  const { events, paginationData, getDeadletterEvents, error, loading } =
    useGetDeadletterEvents()

  useEffect(() => {
    getDeadletterEvents()
    // eslint-disable-next-line
  }, [])

  return (
    <ScreenContainer title="Deadletter Events">
      {error ? <ErrorComponent error={error} /> : null}
      {loading && <LoadingSpinner />}
      <DeadletterEventsTable events={events} paginationData={paginationData} />
    </ScreenContainer>
  )
}

export default DeadletterEventsScreen
