import React, { createContext, useContext, useState } from "react"

interface MenuContextType {
  menuIsShowing: boolean
  setMenuIsShowing: (value: boolean) => void
  toggleMenu: () => void
}

const MenuContext = createContext<MenuContextType | undefined>(undefined)

export const useMenu = () => {
  const context = useContext(MenuContext)
  if (context === undefined) {
    throw new Error("useMenu must be used within a MenuProvider")
  }
  return context
}

export const MenuProvider: React.FC = ({ children }) => {
  const [menuIsShowing, setMenuIsShowing] = useState(true)

  const toggleMenu = () => {
    setMenuIsShowing(!menuIsShowing)
  }

  return (
    <MenuContext.Provider
      value={{ setMenuIsShowing, menuIsShowing, toggleMenu }}
    >
      {children}
    </MenuContext.Provider>
  )
}
