import React, { FunctionComponent } from "react"
import { useAuth } from "../../services/auth-service"
import { Button } from "@mui/material"
import { useTheme } from "@mui/material"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSignOutAlt } from "@fortawesome/pro-regular-svg-icons/faSignOutAlt"

interface LogoutButtonProps {
  menuIsShowing: boolean
}

const LogoutButton: FunctionComponent<LogoutButtonProps> = ({ menuIsShowing }) => {
  const auth = useAuth()

  const handleAuthLogout = async () => {
    await auth.handleLogout()
  }

  const theme = useTheme()

  return (
    <Button 
      onClick={handleAuthLogout}
      sx={{
        color: theme.palette.background.default,
        backgroundColor: theme.palette.primary.main,
        "&:hover": {
          backgroundColor: theme.palette.primary.main + "CC",
        },
        width: "80%",
        height: "40px"
      }}
    >
      {menuIsShowing ? "LOGOUT" : ""}
      <FontAwesomeIcon icon={faSignOutAlt} style={menuIsShowing ? { marginLeft: "10px" } : {marginLeft: "0px"}} />
    </Button>
  )
}

export default LogoutButton
