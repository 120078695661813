import React from "react"
import { Box, Button, Typography } from "@mui/material"

const ForbiddenPage = () => {
  const activeOrganization = localStorage.getItem("activeOrganization")

  return (
    <Box
      sx={{
        width: "100%",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <Typography variant="body1">
        You do not have access to this page. If you think this is an error, make
        sure you are accessing the correct organization and have permissions to
        perform this action. You currently have
        <b>
          {" "}
          {(activeOrganization && JSON.parse(activeOrganization).name) ||
            "no organization"}
        </b>{" "}
        active.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          window.location.href = "/"
        }}
      >
        Go to Dashboard
      </Button>
    </Box>
  )
}

export default ForbiddenPage
