import { Button, Divider, FormControlLabel, Switch, TextField, Box } from "@mui/material"
import { useFormik } from "formik"
import React, { FunctionComponent } from "react"
import toast from "react-hot-toast"
import * as Yup from "yup"
import Dialog from "../../../components/shared/Dialog"
import ErrorComponent from "../../../components/shared/Error"
import useCreateAnnouncement from "../hooks/useCreateAnnouncement"
import { Announcement } from "../../../types"
import useUpdateAnnouncement from "../hooks/useUpdateAnnouncement"
import styles from "./AnnouncementDialog.module.css"
import { lightTheme } from "../../../constants/theme"

interface Props {
  isOpen: boolean
  close: () => void
  announcement?: Announcement | undefined
  onSuccess?: () => void
}

const AnnouncementDialog: FunctionComponent<Props> = ({
  isOpen,
  close,
  announcement,
  onSuccess,
}) => {
  const { createAnnouncement, error, loading } = useCreateAnnouncement()
  const {
    updateAnnouncement,
    error: updateError,
    loading: updateLoading,
  } = useUpdateAnnouncement()

  const validationSchema = Yup.object().shape({
    title: Yup.string().nullable().optional(),
    message: Yup.string().required(),
    active: Yup.boolean().required(),
    notify: Yup.boolean().required(),
  })

  const handleClose = () => {
    close()
  }

  const formik = useFormik({
    initialValues: {
      title: null,
      message: "",
      active: true,
      notify: true,
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      const houseId = localStorage.getItem("activeOrganizationId")
      if (houseId) {
        if (announcement) {
          const body = {
            title: values.title,
            message: values.message,
            active: values.active,
          }
          const updatedAnnouncement = await updateAnnouncement(
            Number(houseId),
            Number(announcement.id),
            body,
            handleUpdateSuccess
          )
          if (updatedAnnouncement) {
            onSuccess && onSuccess()
          }
        } else {
          const body = {
            title: values.title,
            message: values.message,
            active: values.active,
            notify: values.notify,
          }
          const createdAnnouncement = await createAnnouncement(
            Number(houseId),
            body,
            handleCreateSuccess
          )
          if (createdAnnouncement) {
            formik.resetForm()
            onSuccess && onSuccess()
          }
        }
      }
    },
  })

  const handleCreateSuccess = () => {
    toast.success(`Successfully created announcement`)
    close()
  }

  const handleUpdateSuccess = () => {
    toast.success(`Successfully updated announcement`)
    close()
  }

  React.useEffect(() => {
    if (announcement) {
      formik.setFieldValue("title", announcement.title)
      formik.setFieldValue("message", announcement.message)
      formik.setFieldValue("active", announcement.active)
      formik.setFieldValue("notify", announcement.notify)
    }
  }, [announcement])

  return (
    <Dialog
      title={''}
      isOpen={isOpen}
      handleClose={handleClose}
      loading={loading}
      content={
        <>
          <Box className={styles.dialogTitle}>
            {announcement ? "Update Announcement" : "New Announcement"}
          </Box>
          <Divider />
          {error && <ErrorComponent error={error} />}
          {updateError && <ErrorComponent error={updateError} />}
          <TextField
            margin="dense"
            id="title"
            name="title"
            label={"Title"}
            fullWidth
            variant="outlined"
            onChange={formik.handleChange}
            error={!!formik.errors.title}
            value={formik.values.title}
          />
          <TextField
            margin="dense"
            id="message"
            name="message"
            label="Message"
            fullWidth
            variant="outlined"
            required
            multiline
            rows={8}
            maxRows={Infinity}
            onChange={formik.handleChange}
            error={!!formik.errors.message}
            value={formik.values.message}
          />
          {announcement && (
            <>
              <p>
                Created by <b>{announcement.author.name}</b> on{" "}
                {new Date(announcement.created_at).toLocaleString()}
              </p>
              <p>
                Last updated:{" "}
                {new Date(announcement.updated_at).toLocaleString()}
              </p>
            </>
          )}
          <FormControlLabel
            control={
              <Switch
                checked={formik.values.active}
                onChange={(event) => {
                  formik.setFieldValue("active", event.target.checked)
                  if (!event.target.checked) {
                    formik.setFieldValue("notify", false)
                  }
                }}
                name="active"
              />
            }
            label="Set as active (visible to members)"
          />
          {!announcement && (
            <FormControlLabel
              control={
                <Switch
                  checked={formik.values.notify && formik.values.active}
                  onChange={formik.handleChange}
                  name="notify"
                  disabled={!formik.values.active}
                />
              }
              label="Send notification to members when announcement posts"
            />
          )}
        </>
      }
      buttonContent={
        <>
          <Button onClick={handleClose} color="error" variant="outlined">
            Close
          </Button>
          <Button
            onClick={formik.submitForm}
            disabled={loading || updateLoading}
            className={styles.createButton}
            sx={{ 
                backgroundColor: lightTheme.palette.primary.main, 
                color: lightTheme.palette.background.default,
                transition: 'all 0.3s',
                "&:hover": {
                    opacity: '0.8',
                    backgroundColor: lightTheme.palette.primary.main
                }  
            }}
          >
            {announcement ? "Update" : "Create"}
          </Button>
        </>
      }
    />
  )
}

export default AnnouncementDialog
