import { useCallback, useState } from "react"
import config from "../../../config"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { OrganizationMemberRole } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useGetOrganizationMemberRoles = () => {
  const { loading, setLoading, error, setError, authBearer } =
    useHandleRequest()
  const [data, setData] = useState<OrganizationMemberRole[]>()

  const request = useCallback((): Promise<OrganizationMemberRole[]> => {
    return makeRequest<OrganizationMemberRole[]>(
      `${config.apiUrl}/v1/organizations/members/roles`,
      {
        headers: {
          authorization: `Bearer ${authBearer}`,
        },
      }
    )
  }, [authBearer])

  const getOrganizationMemberRoles = useCallback(async () => {
    setLoading(true)
    setError(null)
    let results
    try {
      results = await request()
      setData(results)
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }

    return results
  }, [request, setLoading, setError])

  return {
    loading,
    error,
    getOrganizationMemberRoles,
    roles: data,
  }
}

export default useGetOrganizationMemberRoles
