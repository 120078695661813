import { useCallback, useState } from "react"
import config from "../../../config"
import useHandleRequest from "../../../hooks/useHandleRequest"
import makeRequest from "../../../utils/make-request"
import { AttendanceItem } from "../../../types"

const useUpdateMemberAttendance = () => {
  const { loading, setLoading, error, setError, authBearer } =
    useHandleRequest()
  const [data, setData] = useState<AttendanceItem>()

  const request = useCallback(
    (
      organizationId: number,
      attendanceItemId: number,
      memberId: number,
      present: boolean
    ): Promise<AttendanceItem> => {
      return makeRequest<AttendanceItem>(
        `${config.apiUrl}/v1/attendance/items/${attendanceItemId}/members/${memberId}`,
        {
          method: "PUT",
          headers: {
            authorization: `Bearer ${authBearer}`,
            "Content-Type": "application/json",
            "X-Organization-Id": `${organizationId}`,
          },
          body: JSON.stringify({ present }),
        }
      )
    },
    [authBearer]
  )

  const updateMemberAttendance = useCallback(
    async (
      organizationId: number,
      attendanceItemId: number,
      memberId: number,
      present: boolean,
      onSuccess: Function
    ) => {
      setLoading(true)
      setError(null)
      let results
      try {
        results = await request(
          organizationId,
          attendanceItemId,
          memberId,
          present
        )
        setData(results)
        if (onSuccess) {
          await onSuccess()
        }
      } catch (error) {
        setError(error)
      } finally {
        setLoading(false)
      }

      return results
    },
    [request, setLoading, setError]
  )

  return {
    loading,
    error,
    updateMemberAttendance,
    attendanceItem: data,
  }
}

export default useUpdateMemberAttendance
