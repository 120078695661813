import React, { FunctionComponent, useState } from "react"
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow"
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell"
import { OrganizationMember } from "../../../types"
import { Avatar, Button } from "@mui/material"
import toast from "react-hot-toast"
import EditMemberRequiredStudyHoursDialog from "./EditMemberRequiredStudyHoursDialog"

type Props = {
  studyHour: OrganizationMember
  reloadStudyHours: () => void
}

const StudyHoursRow: FunctionComponent<Props> = ({
  studyHour,
  reloadStudyHours,
}) => {
  const [editRequiredHoursOpen, setEditRequiredHoursOpen] = useState(false)

  const editRequiredHoursSuccess = async () => {
    toast.success("Required hours updated successfully")
    setEditRequiredHoursOpen(false)
    await reloadStudyHours()
  }

  return (
    <>
      <BasicTableRow key={studyHour.id}>
        <BasicTableCell component="th" scope="row">
          <Avatar
            alt={studyHour.user.name}
            src={`${studyHour.user.profile_photo}`}
          />
        </BasicTableCell>
        <BasicTableCell component="th" scope="row">
          {studyHour.user.name}
        </BasicTableCell>
        <BasicTableCell component="th" scope="row">
          <b>{studyHour.completed_study_hours}</b> out of{" "}
          <b>{studyHour.required_study_hours}</b> hour
          {studyHour.required_study_hours === 1 ? "" : "s"} completed (
          <b>{studyHour.percentage}%</b>)
        </BasicTableCell>
        <BasicTableCell component="th" scope="row">
          <Button
            variant="contained"
            sx={{
              marginLeft: 2,
              padding: 0.5,
            }}
            onClick={() => setEditRequiredHoursOpen(true)}
          >
            Required Hours
          </Button>
        </BasicTableCell>
      </BasicTableRow>
      <EditMemberRequiredStudyHoursDialog
        isOpen={editRequiredHoursOpen}
        close={() => setEditRequiredHoursOpen(false)}
        member={studyHour}
        onSuccess={editRequiredHoursSuccess}
      />
    </>
  )
}

export default StudyHoursRow
