import { Box, Button, CircularProgress } from "@mui/material"
import React, { FunctionComponent, useEffect, useState } from "react"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/ScreenContainer"
import { scopes } from "../../scopes"
import { useAuth } from "../../services/auth-service"
import useGetSchools from "./hooks/useGetSchools"
import SchoolsTable from "./components/SchoolsTable"
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"
import SchoolsDialog from "./components/SchoolsDialog"

const SchoolsScreen: FunctionComponent = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const {
    getSchools,
    paginationData,
    schools,
    loading: schoolsLoading,
    error: schoolsError,
  } = useGetSchools()
  const { hasScope } = useAuth()

  useEffect(() => {
    getSchools()
  }, [])

  const canCreateSchools = hasScope(scopes.schools.create)

  if (schoolsLoading) {
    return (
      <ScreenContainer>
        <Box
          display="flex"
          width="100%"
          sx={{
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            alignSelf: "center",
            mb: "24px",
          }}
        >
          <CircularProgress />
        </Box>
      </ScreenContainer>
    )
  }

  return (
    <ScreenContainer
      title={"Schools [INTERNAL]"}
      subtitle="Manage schools displayed to end users"
      requiresScope={scopes.schools.create}
      icon={<AddCircleOutlineIcon />}
      iconAction={() => setIsDialogOpen(true)}
    >
      <Box
        display="flex"
        width="100%"
        sx={{
          flexDirection: "column",
          mb: "24px",
        }}
      >
        {schoolsError && <ErrorComponent error={schoolsError} />}
        {schoolsLoading && <CircularProgress />}
        <SchoolsTable
          schools={schools}
          paginationData={paginationData}
          refreshData={() => getSchools()}
        />
      </Box>
      <SchoolsDialog
        isOpen={isDialogOpen}
        close={() => setIsDialogOpen(false)}
        onSuccess={() => getSchools()}
      />
    </ScreenContainer>
  )
}

export default SchoolsScreen
