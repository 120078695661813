import { useCallback } from "react"
import config from "../../../config"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { DeadletterEvent } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useDeleteDeadletterEvent = () => {
  const { loading, setLoading, error, setError, authBearer } =
    useHandleRequest()

  const request = useCallback(
    (id: number): Promise<DeadletterEvent> => {
      return makeRequest<DeadletterEvent>(
        `${config.apiUrl}/v1/events/deadletter/${id}`,
        {
          method: "DELETE",
          headers: {
            authorization: `Bearer ${authBearer}`,
            "Content-Type": "application/json",
          },
        }
      )
    },
    [authBearer]
  )

  const deleteDeadletterEvent = useCallback(
    async (id: number, onSuccess: Function) => {
      setLoading(true)
      setError(null)
      let result
      try {
        result = await request(id)
        if (onSuccess) {
          await onSuccess()
        }
      } catch (error) {
        setError(error)
      } finally {
        setError(null)
        setLoading(false)
      }

      return result
    },
    [request, setLoading, setError]
  )

  return { deleteDeadletterEvent, loading, error }
}

export default useDeleteDeadletterEvent
