import React, { FunctionComponent, useEffect, useState } from "react"
import {
  Box,
  CircularProgress,
  Typography,
  Button,
  Avatar,
} from "@mui/material"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/ScreenContainer"
import useGetHouseById from "../Organizations/hooks/useGetHouseById"
import { useParams } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons"
import { useNavigate } from "react-router-dom"
import useGetPollById from "./hooks/useGetPollById"
import useVoteInPollById from "./hooks/useVoteInPollById"
import toast from "react-hot-toast"
import { useAuth } from "../../services/auth-service"
import icons from "../../assets/Icons/icons"
import useEndPollById from "./hooks/useEndPollById"

type Params = {
  pollId?: string
}

const PollDetailsScreen: FunctionComponent = () => {
  const params: Params = useParams()
  const pollId = Number(params.pollId)
  const [selectedChoices, setSelectedChoices] = useState<number[]>([])
  const [submitting, setSubmitting] = useState(false)
  const [changingAnswers, setChangingAnswers] = useState(false)
  const [selectedChoiceForVoters, setSelectedChoiceForVoters] = useState<
    number | null
  >(null)

  const auth = useAuth()
  const userId = auth.getUser()?.id

  const navigate = useNavigate()

  const {
    getHouseById,
    house,
    loading: organizationLoading,
    error: organizationError,
  } = useGetHouseById()
  const { getPoll, poll, error: pollError } = useGetPollById()
  const { voteInPoll, error: voteError } = useVoteInPollById()
  const { endPoll, error: endError } = useEndPollById()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const organizationId = localStorage.getItem("activeOrganizationId")

        if (organizationId) {
          await getHouseById(Number(organizationId))
          await getPoll(Number(organizationId), pollId)

          if (house) {
            localStorage.setItem("activeOrganization", JSON.stringify(house))
          }
        }
      } catch (err) {
        console.error(`Error loading organization: ${organizationError}`)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      getPoll(Number(localStorage.getItem("activeOrganizationId")), pollId)
    }, 2000)

    return () => clearInterval(interval)
  }, [])

  if (organizationLoading) {
    return (
      <ScreenContainer>
        <Box
          display="flex"
          width="100%"
          sx={{
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            alignSelf: "center",
            mb: "24px",
          }}
        >
          <CircularProgress />
        </Box>
      </ScreenContainer>
    )
  }

  if (!poll) {
    return <ScreenContainer title={"Poll not found"}></ScreenContainer>
  }

  let subtitleText = "Poll results are shown below"

  if (poll.status === "active" && !poll.user_has_voted) {
    if (poll.multiple_answers) {
      subtitleText =
        "Cast your vote below to see poll results. You can select multiple choices."
    } else {
      subtitleText = "Cast your vote below to see poll results"
    }
  }

  if (poll.status === "active" && poll.user_has_voted) {
    if (!poll.end_at) {
      subtitleText = "This poll is currently active"
    } else {
      const endDate = new Date(poll.end_at)
      subtitleText = `This poll is currently active and will end at ${endDate.toLocaleString()}`
    }
  }

  if (poll.status === "ended") {
    subtitleText = "This poll has closed and the results are final"
  }

  const handleSubmit = async () => {
    setSubmitting(true)
    await voteInPoll(
      Number(localStorage.getItem("activeOrganizationId")),
      pollId,
      selectedChoices,
      voteInPollSuccess
    )
    setSelectedChoices([])
    setSubmitting(false)
    setChangingAnswers(false)
    await getPoll(Number(localStorage.getItem("activeOrganizationId")), pollId)
  }

  const voteInPollSuccess = () => {
    toast.success("Vote submitted successfully")
  }

  const endPollNow = async () => {
    await endPoll(
      Number(localStorage.getItem("activeOrganizationId")),
      pollId,
      endPollSuccess
    )
  }

  const endPollSuccess = () => {
    toast.success("Poll ended successfully")
  }

  const renderVoters = (choiceId: number) => {
    const choice = poll.choices.find((c) => c.id === choiceId)
    if (!choice || !choice.responses.length) return null

    return (
      <Box sx={{ display: "flex", marginTop: 2, marginBottom: 2 }}>
        {choice.responses.map((item) =>
          item.user ? (
            <Box
              key={item.id}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginRight: 1,
              }}
            >
              <Avatar alt={item.user.name} src={`${item.user.profile_photo}`} />
              <Typography sx={{ marginTop: 1, fontSize: 10, color: "#000000" }}>
                {item.user.name}
              </Typography>
            </Box>
          ) : null
        )}
      </Box>
    )
  }

  const handleChoicePress = (choiceId: number) => {
    if (poll.multiple_answers) {
      setSelectedChoices((prevChoices) =>
        prevChoices.includes(choiceId)
          ? prevChoices.filter((id) => id !== choiceId)
          : [...prevChoices, choiceId]
      )
    } else {
      setSelectedChoices([choiceId])
    }
  }

  return (
    <ScreenContainer>
      <Box
        sx={{ fontSize: "2rem", margin: "10px", cursor: "pointer" }}
        onClick={() => navigate("/dashboard")}
      >
        <FontAwesomeIcon icon={faArrowLeft} />
      </Box>
      <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 2,
            marginBottom: 2,
          }}
        >
          <FontAwesomeIcon
            icon={icons.poll}
            style={{ width: "55px", height: "55px" }}
          />
          <Typography
            sx={{
              fontSize: 20,
              fontWeight: "bold",
              textAlign: "center",
              marginTop: 1,
              color: "#000000",
            }}
          >
            {poll.title}
          </Typography>
          <Typography
            sx={{
              fontSize: 15,
              textAlign: "center",
              marginTop: 1,
              marginBottom: 2,
              color: "#333333",
            }}
          >
            {subtitleText}
          </Typography>
          {voteError && <ErrorComponent error={voteError} />}
          {pollError && <ErrorComponent error={pollError} />}
          {endError && <ErrorComponent error={endError} />}
        </Box>
        {(poll.user_has_voted &&
          poll.status === "active" &&
          !changingAnswers) ||
        poll.status === "ended" ? (
          poll.choices.map((choice) => (
            <Box key={choice.id} sx={{ marginBottom: 2 }}>
              <Box
                onClick={() =>
                  setSelectedChoiceForVoters(
                    selectedChoiceForVoters === choice.id ? null : choice.id
                  )
                }
              >
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: "bold",
                    color: "#000000",
                  }}
                >
                  {choice.title} ({choice.total_responses}/
                  {poll.total_responses})
                </Typography>

                <Box
                  sx={{
                    height: 10,
                    backgroundColor: "#E0E0E0",
                    borderRadius: 5,
                    marginTop: 1,
                    marginBottom: 1,
                  }}
                >
                  <Box
                    sx={{
                      height: "100%",
                      borderRadius: 5,
                      width: `${
                        (choice.total_responses / poll.total_responses) * 100
                      }%`,
                      maxWidth: "100%",
                      backgroundColor: "#ff4444",
                    }}
                  />
                </Box>
              </Box>
              {selectedChoiceForVoters === choice.id && renderVoters(choice.id)}
            </Box>
          ))
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {poll.choices.map((choice, index) => (
              <Button
                key={index}
                variant={
                  selectedChoices.includes(choice.id) ? "contained" : "outlined"
                }
                onClick={() => handleChoicePress(choice.id)}
                sx={{
                  padding: 1,
                  marginY: 0.5,
                  borderColor: "#ccc",
                  borderRadius: 1,
                }}
              >
                <Typography
                  sx={{
                    fontSize: 16,
                    color: "#000000",
                    fontWeight: selectedChoices.includes(choice.id)
                      ? "bold"
                      : "normal",
                  }}
                >
                  {choice.title}
                </Typography>
              </Button>
            ))}
            {poll.anonymous && (
              <Typography sx={{ color: "#333333", marginTop: 1 }}>
                Your vote is anonymous
              </Typography>
            )}
            <Button
              onClick={handleSubmit}
              disabled={submitting}
              sx={{
                marginTop: 2,
                backgroundColor: "#fff",
                color: "#000",
                border: "1px solid #000",
                width: "100%",
                "&:hover": {
                  backgroundColor: "#ff4444",
                  border: "0px",
                  color: "#fff",
                },
              }}
            >
              Submit
            </Button>
          </Box>
        )}
        {poll.status === "active" &&
          poll.change_answers &&
          poll.user_has_voted &&
          !changingAnswers && (
            <Button
              onClick={() => {
                setSelectedChoices([])
                setChangingAnswers(true)
              }}
              sx={{
                marginTop: 2,
                backgroundColor: "#fff",
                color: "#000",
                border: "1px solid #000",
                width: "100%",
                "&:hover": {
                  backgroundColor: "#ff4444",
                  border: "0px",
                  color: "#fff",
                },
              }}
            >
              Change Answers
            </Button>
          )}
        {poll.status !== "ended" && poll.author.id === userId && (
          <Button
            onClick={endPollNow}
            disabled={submitting}
            sx={{
              marginTop: 2,
              backgroundColor: "#fff",
              color: "#000",
              border: "1px solid #000",
              width: "100%",
              "&:hover": {
                backgroundColor: "#ff4444",
                border: "0px",
                color: "#fff",
              },
            }}
          >
            End Poll Now
          </Button>
        )}
      </Box>
    </ScreenContainer>
  )
}

export default PollDetailsScreen
