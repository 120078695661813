// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"
import config from "./config"

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const isProduction = config.environmentName === "production"

const firebaseConfig = {
  apiKey: isProduction
    ? "AIzaSyBNh0fhDw8O4-an-W8jKVewj6N-YAo9FmA"
    : "AIzaSyA29EGgHb6Fubn7eoAJCbn0uwmM1ALgnjU",
  authDomain: isProduction
    ? "greekchapter.firebaseapp.com"
    : "greekchapter-staging.firebaseapp.com",
  projectId: isProduction ? "greekchapter" : "greekchapter-staging",
  storageBucket: isProduction
    ? "greekchapter.appspot.com"
    : "greekchapter-staging.appspot.com",
  messagingSenderId: isProduction ? "386461592551" : "608962040290",
  appId: isProduction
    ? "1:386461592551:web:2d041088f3a426ee80eb42"
    : "1:608962040290:web:206ab58f08f16df8db69bb",
  measurementId: isProduction ? "G-BXWP9HB992" : "G-7NB1SXDL91",
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app)
export default app
