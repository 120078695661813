import { styled, TableRow } from "@mui/material"

const BasicTableRow = styled(TableRow)(
  ({ theme }) => `
 &.MuiTableRow-root {
    &:nth-of-type(odd){
      background-color: none
    }
  }
`
)

export default BasicTableRow
