import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  Pagination,
  Box
} from "@mui/material"
import React, {
  ComponentType,
  FunctionComponent,
  ReactNode,
  useCallback,
} from "react"
import BasicTableCell from "./BasicTableCell"
import BasicTableRow from "./BasicTableRow"
import styles from './BasicTable.module.css'
import useBreakpoint from "../../../hooks/useBreakpoint"
import { lightTheme } from "../../../constants/theme"

export interface PaginationData {
  count: number
  page: number
  rowsPerPage: number
  onPageChange: (event: any, page: number) => void
  onRowsPerPageChange: (event: any) => void
}
interface BasicTableProps {
  columns: (string | ReactNode)[]
  rows: (string | ReactNode)[]
  paginationOptions?: PaginationData
  ColumnHeader?: ComponentType
  hasHeader?: boolean
}

const BasicTable: FunctionComponent<BasicTableProps> = ({
  columns,
  rows,
  paginationOptions,
  ColumnHeader = BasicTableCell,
  hasHeader
}) => {
  const getColumns = useCallback(() => {
    return columns.map((column, index) => (
      <ColumnHeader 
        className={styles.headerCell}
        key={index}
        sx={{ 
          backgroundColor: `${lightTheme.palette.background.default} !important`, 
          color: `${lightTheme.palette.text.primary} !important`,
          overflowX: "auto",
          whiteSpace: "nowrap",
        }}
      >
        {column}
      </ColumnHeader>
    ))
  }, [columns, ColumnHeader])

  const breakpoint = useBreakpoint()

  if(breakpoint === "xxs" || breakpoint === "xs") {
    return (
      <Box className={styles.tableContainer}>
        {paginationOptions && (
          <TablePagination
            count={paginationOptions.count}
            page={paginationOptions.page}
            rowsPerPage={paginationOptions.rowsPerPage}
            onPageChange={paginationOptions.onPageChange}
            onRowsPerPageChange={paginationOptions.onRowsPerPageChange}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            labelRowsPerPage="Rows"
          />
        )}
        <TableContainer sx={{ width: "100%"}}>
          <Table sx={{ minWidth: "90%"}} stickyHeader>
            {hasHeader && (
              <TableHead>
                <BasicTableRow>{getColumns()}</BasicTableRow>
              </TableHead>
            )} 
            <TableBody>{rows}</TableBody>
          </Table>
        </TableContainer>
      </Box>
    )
  }

  return (
    <Box className={styles.tableContainer}>
      <TableContainer>
        <Table stickyHeader>
        {hasHeader && (
              <TableHead className={styles.tableHead}>
                <BasicTableRow>{getColumns()}</BasicTableRow>
              </TableHead>
            )} 
          <TableBody sx={{ overflowX: "hidden"}}>{rows}</TableBody>
        </Table>
      </TableContainer>
      {paginationOptions && (
        <TablePagination
          count={paginationOptions.count}
          page={paginationOptions.page}
          rowsPerPage={paginationOptions.rowsPerPage}
          onPageChange={paginationOptions.onPageChange}
          onRowsPerPageChange={paginationOptions.onRowsPerPageChange}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          labelRowsPerPage="Rows"
        />
      )}
    </Box>
  )
}

export default BasicTable
