import React, { useEffect } from "react";
import { Box, Collapse, Input, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import { PointItem } from "../../../types/points";
import { lightTheme } from "../../../constants/theme";
import styles from "./MobilePointsTable.module.css";
import useGetHouseMembers from "../../Organizations/hooks/useGetHouseMembers";
import useGetPointItems from "../hooks/useGetPointItems";
import Swal from "sweetalert2";
import toast from "react-hot-toast";
import useUpdatePointItems from "../hooks/useUpdatePointItems";

const MobilePointsTable = ({ categories, refreshData }: { categories: string[], refreshData: () => void }) => {
    const [open, setOpen] = React.useState<number>(1);
    const [changedPoint, setChangedPoint] = React.useState<{ memberId: number, points: number | "" } | null>(null);
    const [previousPoints, setPreviousPoints] = React.useState<{ [key: string]: number }>({});
    const [ hasChanged, setHasChanged ] = React.useState<boolean>(false);

    const { getHouseMembers, members } = useGetHouseMembers();
    const { getPointItems, pointItems } = useGetPointItems();
    const { updatePointItem } = useUpdatePointItems();

    useEffect(() => {
        getHouseMembers(Number(localStorage.getItem("activeOrganizationId")));
        getPointItems(Number(localStorage.getItem("activeOrganizationId")));
    }, [refreshData]);

    const handleInputChange = (value: string, memberId: number, column: string) => {
        setHasChanged(true);
        const newPoints = value === "" ? "" : Number(value);
        setChangedPoint({ memberId, points: newPoints });

        const key = `${memberId}-${column}`;
        if (!previousPoints[key]) {
            const currentPoints = pointItems?.items.find((item: PointItem) => item.title === column)?.points.find(p => p.id === memberId)?.points || 0;
            setPreviousPoints(prev => ({ ...prev, [key]: currentPoints }));
        }
    };

    const handlePointChange = (memberId: number, points: number, memberName: string, column: string) => {
        const key = `${memberId}-${column}`;
        const updatedItem = { memberId, points };

        if(hasChanged) {
        Swal.fire({
            title: "Update Points?",
            text: `Update ${memberName}'s points for ${column} to ${updatedItem.points}?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
        })
            .then((result) => {
                if (result.isConfirmed && pointItems?.items) {
                    const pointItem = pointItems.items.find((item: PointItem) => item.title === column);
                    if (pointItem) {
                        updatePointItem(
                            pointItem.id,
                            memberId,
                            Number(localStorage.getItem("activeOrganizationId")),
                            { value: updatedItem.points },
                            () => {
                                setChangedPoint(null); // Clear local state after successful update
                                refreshData(); // Fetch fresh data after update
                                setPreviousPoints(prev => {
                                    const newState = { ...prev };
                                    delete newState[key];
                                    return newState;
                                });
                            }
                        );
                    }
                } else if (!result.isConfirmed) {
                    setChangedPoint(null); // Clear local state on cancel
                    refreshData(); // Fetch fresh data to revert changes
                }
            })
            .catch(() => {
                toast.error("Error updating points");
                refreshData();
            });
            setHasChanged(false);
        }
    };

    return (
        <Box className={styles.mobilePointScreenContainer}>
            {categories.map((column, index) => {
                if (index < 2) {
                    return null;
                } else {
                    return (
                        <Box className={styles.mobilePointItemContainer} key={index}>
                            <Box
                                className={styles.mobilePointCategory}
                                sx={{ borderBottom: `1px solid ${lightTheme.palette.text.primary}` }}
                                onClick={() => setOpen(open === index ? 1 : index)}
                            >
                                <Typography className={styles.categoryTitle} variant="h4" sx={{ fontWeight: "bold" }}>
                                    {column}
                                </Typography>
                                <FontAwesomeIcon icon={open === index ? faChevronUp : faChevronDown} />
                            </Box>
                            <Collapse in={open === index} timeout="auto" unmountOnExit>
                                {members.map((member, index) => {
                                    let points = 0;
                                    pointItems?.items.forEach((item: PointItem) => {
                                        if (item.title === column) {
                                            item.points.forEach((point: { id: number, name: string, points: number }) => {
                                                if (point.id === member.id) {
                                                    points = point.points;
                                                }
                                            });
                                        }
                                    });
                                    const key = `${member.id}-${column}`;
                                    return (
                                        <Box className={styles.mobilePointItem} key={index}>
                                            <Typography className={styles.memberName} variant="h5">
                                                {member.user.name}
                                            </Typography>
                                            <Input
                                                className={styles.pointInput}
                                                type="number"
                                                placeholder="0"
                                                inputProps={{ min: 0 }}
                                                value={changedPoint?.memberId === member.id ? changedPoint.points : points}
                                                onChange={(e) =>
                                                    handleInputChange(e.target.value, member.id, column)
                                                }
                                                onBlur={(e) =>
                                                    handlePointChange(
                                                        member.id,
                                                        Number(e.target.value),
                                                        member.user.name,
                                                        column
                                                    )
                                                }
                                            />
                                        </Box>
                                    );
                                })}
                            </Collapse>
                        </Box>
                    );
                }
            })}
        </Box>
    );
};

export default MobilePointsTable;