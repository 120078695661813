import { useCallback } from "react"
import config from "../../../config"
import useHandleRequest from "../../../hooks/useHandleRequest"
import makeRequest from "../../../utils/make-request"

const useMarkNotificationsAsRead = () => {
  const { loading, setLoading, error, setError, authBearer } =
    useHandleRequest()

  const request = useCallback((): Promise<void> => {
    return makeRequest<void>(
      `${config.apiUrl}/v1/users/me/notifications/read/all`,
      {
        method: "POST",
        headers: {
          authorization: `Bearer ${authBearer}`,
          "Content-Type": "application/json",
        },
      }
    )
  }, [authBearer])

  const markNotificationsAsRead = useCallback(
    async (onSuccess?: Function) => {
      setLoading(true)
      setError(null)
      let result
      try {
        result = await request()
        if (onSuccess) {
          await onSuccess()
        }
      } catch (error) {
        setError(error)
      } finally {
        setError(null)
        setLoading(false)
      }

      return result
    },
    [request, setLoading, setError]
  )

  return { markNotificationsAsRead, loading, error }
}

export default useMarkNotificationsAsRead
