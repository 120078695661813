import React, { FunctionComponent, useEffect } from "react"
import ErrorComponent from "../../components/shared/Error"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import ScreenContainer from "../../components/shared/ScreenContainer"
import useGetEvents from "./hooks/useGetEvents"
import EventsTable from "./components/EventsTable"

const EventsScreen: FunctionComponent = () => {
  const { events, paginationData, getEvents, error, loading } = useGetEvents()

  useEffect(() => {
    getEvents()
    // eslint-disable-next-line
  }, [])

  return (
    <ScreenContainer title="Events">
      {error ? <ErrorComponent error={error} /> : null}
      {loading && <LoadingSpinner />}
      <EventsTable events={events} paginationData={paginationData} />
    </ScreenContainer>
  )
}

export default EventsScreen
