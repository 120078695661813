import React, { FunctionComponent, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import ErrorComponent from "../../components/shared/Error"
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner"
import { useAuth } from "../../services/auth-service"
import enforceError from "../../utils/enforce-error"
import {
  Container,
  Paper,
  Grid,
  Typography,
  TextField,
  Button,
  Box,
} from "@mui/material"
import "./LoginScreen.css"
import redLogo from "../../assets/redLogo.svg"
import largeImage from "../../assets/loginImage.svg"

interface RedirectParamsType {
  pathname: string
  search?: string
}

interface SearchParamType {
  name: string
  value: string
}

const createSearchQuery = (searchParams: SearchParamType[]) => {
  return searchParams.map((param) => `${param.name}=${param.value}`).join("&")
}

const LoginComponent: FunctionComponent = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const [emailError, setEmailError] = useState("")
  const [passwordError, setPasswordError] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const auth = useAuth()
  const navigate = useNavigate()
  const location: any = useLocation()
  const redirectPath = location?.state?.redirectPath || "/"

  const navigateToSignup = () => {
    navigate("/signup")
  }

  const handleSubmit = async () => {
    // Handle the submission of email and password here
    try {
      if (!email) {
        setEmailError("Email is required")
      } else if (!password) {
        setPasswordError("Password is required")
      } else {
        setLoading(true)
        await auth.handleLogin(email, password)
        const navigationProps: RedirectParamsType = {
          pathname: redirectPath,
        }
        const redirectSearchParams = location?.state?.searchParams
        if (redirectSearchParams?.length) {
          navigationProps.search = createSearchQuery(redirectSearchParams)
        }
        navigate(navigationProps)
      }
    } catch (error) {
      setError(enforceError(error))
    } finally {
      setLoading(false)
    }
  }

  if (auth.isAuthenticated()) {
    const navigationProps: RedirectParamsType = {
      pathname: redirectPath,
    }
    const redirectSearchParams = location?.state?.searchParams
    if (redirectSearchParams?.length) {
      navigationProps.search = createSearchQuery(redirectSearchParams)
    }
    navigate(navigationProps)
  }

  return (
    <div className="page-container">
      <div className="login-wrapper">
        <Container maxWidth="sm" className="login-container">
          <Paper elevation={0} style={{ padding: 20 }}>
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12}>
                <img
                  className="login-logo"
                  alt="Greek Chapter Logo"
                  src={redLogo}
                />
                <Typography variant="h4" align="center" fontWeight="bold">
                  Login
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {error ? <ErrorComponent error={error} /> : null}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  className="login-input"
                  label="Email"
                  fullWidth
                  variant="outlined"
                  value={email}
                  disabled={loading}
                  onChange={(e) => {
                    setEmail(e.target.value)
                    setEmailError("")
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit()
                    }
                  }}
                />
                {emailError ? (
                  <Box sx={{ color: "red", fontSize: 12 }}>{emailError}</Box>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  className="login-input"
                  label="Password"
                  fullWidth
                  type="password"
                  variant="outlined"
                  value={password}
                  disabled={loading}
                  onChange={(e) => {
                    setPassword(e.target.value)
                    setPasswordError("")
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit()
                    }
                  }}
                />
                {passwordError ? (
                  <Box sx={{ color: "red", fontSize: 12 }}>{passwordError}</Box>
                ) : null}
              </Grid>
              <Grid item xs={12} justifyContent="flex-start">
                <Typography className="forgot-password-grid">
                  <a className="forgot-password">Forgot Password?</a>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  className="login-button"
                  color="primary"
                  fullWidth
                  onClick={handleSubmit}
                  sx={{ textTransform: "none", fontWeight: "bold" }}
                  disabled={loading}
                >
                  Login
                </Button>
              </Grid>
            </Grid>
            <Typography
              variant="body1"
              align="center"
              color="#505050"
              sx={{ mt: 2 }}
            >
              New to Greek Chapter?{" "}
              <u
                style={{
                  cursor: "pointer",
                  textDecoration: "none",
                  fontWeight: "bold",
                }}
                onClick={navigateToSignup}
              >
                Create an account
              </u>
            </Typography>
          </Paper>
        </Container>
      </div>
      <img className="login-image" alt="Login Image" src={largeImage} />
    </div>
  )
}

export default LoginComponent
