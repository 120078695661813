import React from "react"
import BasicTable, {
  PaginationData,
} from "../../../components/shared/BasicTable/BasicTable"
import { School } from "../../../types"
import SchoolsRow from "./SchoolsRow"

interface MemberPointsTableProps {
  schools: School[]
  paginationData: PaginationData
  refreshData: () => void
}

const getRows = (schools: School[], refreshData: () => void) => {
  return schools.map((school, index) => {
    return <SchoolsRow key={index} school={school} refreshData={refreshData} />
  })
}

const SchoolsTable = ({
  schools,
  paginationData,
  refreshData,
}: MemberPointsTableProps) => {
  const columns: string[] = ["ID", "Icon", "Name", "Acronym", "Location"]

  return (
    <BasicTable
      columns={columns}
      rows={getRows(schools, refreshData)}
      paginationOptions={paginationData}
    />
  )
}

export default SchoolsTable
