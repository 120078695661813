import { useCallback, useState } from "react"
import config from "../../../config"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Me } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useGetMe = () => {
  const { loading, setLoading, error, setError, authBearer } =
    useHandleRequest()
  const [data, setData] = useState<Me>()

  const houseId = localStorage.getItem("activeOrganizationId")
  const organizationIdNumber = (houseId && Number(houseId)) || null

  const headers: { authorization: string; "X-Organization-Id"?: string } = {
    authorization: `Bearer ${authBearer}`,
  }

  if (organizationIdNumber !== null) {
    headers["X-Organization-Id"] = `${organizationIdNumber}`
  }

  const request = useCallback((): Promise<Me> => {
    return makeRequest<Me>(`${config.apiUrl}/v1/users/me`, {
      headers,
    })
  }, [authBearer])

  const getMe = useCallback(async () => {
    setLoading(true)
    setError(null)
    let results
    try {
      results = await request()
      setData(results)
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }

    return results
  }, [request, setLoading, setError])

  return {
    loading,
    error,
    getMe,
    me: data,
  }
}

export default useGetMe
