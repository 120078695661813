import { useCallback, useState, useEffect } from "react"
import { useAuth } from "../services/auth-service"
import assertAuthToken from "../utils/assertAuthToken"
import enforceError from "../utils/enforce-error"

const useHandleRequest = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<Error | null>(null)

  const handleSetError = useCallback((error: any) => {
    if (error) {
      setError(enforceError(error))
    }
  }, [])

  const auth = useAuth()
  const authToken = auth.getToken()

  // Use useEffect to update authBearer when the authToken changes
  useEffect(() => {
    const newAuthToken = assertAuthToken(authToken)
    setAuthBearer(newAuthToken)
  }, [authToken])

  const [authBearer, setAuthBearer] = useState<string | null>(() =>
    assertAuthToken(authToken)
  )

  if (authBearer === null) {
    // Handle the case where the token is not available yet
    return {
      loading,
      setLoading,
      error,
      setError: handleSetError,
      authBearer: null,
    }
  }

  return {
    loading,
    setLoading,
    error,
    setError: handleSetError,
    authBearer,
  }
}

export default useHandleRequest
