import { useCallback, useState } from "react"
import config from "../../../config"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { Term } from "../../../types"
import makeRequest from "../../../utils/make-request"

const useGetOrganizationTerms = () => {
  const { loading, setLoading, error, setError, authBearer } =
    useHandleRequest()
  const [data, setData] = useState<Term[]>()

  const request = useCallback(
    (organizationId: number): Promise<Term[]> => {
      return makeRequest<Term[]>(
        `${config.apiUrl}/v1/organizations/${organizationId}/terms`,
        {
          headers: {
            authorization: `Bearer ${authBearer}`,
            "X-Organization-Id": `${organizationId}`,
          },
        }
      )
    },
    [authBearer]
  )

  const getOrganizationTerms = useCallback(
    async (organizationId: number) => {
      setLoading(true)
      setError(null)
      let results
      try {
        results = await request(organizationId)
        setData(results)
      } catch (error) {
        setError(error)
      } finally {
        setLoading(false)
      }

      return results
    },
    [request, setLoading, setError]
  )

  return {
    loading,
    error,
    getOrganizationTerms,
    terms: data,
  }
}

export default useGetOrganizationTerms
