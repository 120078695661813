import { useCallback, useState } from "react"
import config from "../../../config"
import useHandleRequest from "../../../hooks/useHandleRequest"
import makeRequest from "../../../utils/make-request"
import { School } from "../../../types"

const useCreateSchool = () => {
  const { loading, setLoading, error, setError, authBearer } =
    useHandleRequest()
  const [data, setData] = useState<School>()

  const request = useCallback(
    (createBody: object): Promise<School> => {
      return makeRequest<School>(`${config.apiUrl}/v1/schools`, {
        method: "POST",
        headers: {
          authorization: `Bearer ${authBearer}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...createBody }),
      })
    },
    [authBearer]
  )

  const createSchool = useCallback(
    async (createBody: object, onSuccess: Function) => {
      setLoading(true)
      setError(null)
      let results
      try {
        results = await request(createBody)
        setData(results)
        if (onSuccess) {
          await onSuccess()
        }
      } catch (error) {
        setError(error)
      } finally {
        setLoading(false)
      }

      return results
    },
    [request, setLoading, setError]
  )

  return {
    loading,
    error,
    createSchool,
    school: data,
  }
}

export default useCreateSchool
